import { usePagination } from "@ajna/pagination";
import {
    Tabs,
    Box,
    Flex,
    Text,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getWhatsappNumbers } from "../../API/business";
import WhatsappNumberTable from "../../components/Business/WhatsappNumber/WhatsappNumberTable";
import Back from "../../components/UI/Back";
import CustomPagination from "../../components/UI/CustomPagination";
import Loader from "../../components/UI/Loader";

const WhatsappNumbers = () => {
    const { numberId } = useParams();
    const [myCurrentPage, setMyCurrentPage] = useState(1);
    const [dataToShow, setDataToShow] = useState(10);
    const [globalFilter, setGlobalFilter] = useState("");
    const [active, setActive] = useState("pending");

    const { isLoading, data } = useQuery(
        ["whatsappNumberData", myCurrentPage, dataToShow, active],
        () => {
            return getWhatsappNumbers(active, numberId, myCurrentPage, dataToShow);
        }
    );

    const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
        pagesCount: data?.totalPages,
        limits: {
            outer: 3,
            inner: 3,
        },
        initialState: { currentPage: 1 },
    });

    const handlePageChange = (nextPage: number): void => {
        setCurrentPage(nextPage);
        setMyCurrentPage(nextPage);
    };

    const switchTab = (tab: string) => {
        setActive(tab);
        setMyCurrentPage(1);
        setCurrentPage(1);
        setDataToShow(10);
    };
    return (
        <>
            <Box mt={"47px"} pl={"32px"} w="full" maxW={"80vw"} h="full">
                <Back
                    path="/business"
                    title="Back"
                    textProps={{
                        fontSize: "12px",
                        color: "grays.12",
                    }}
                />
                <Flex alignItems={"center"} mb={6}>
                    <Text fontWeight={700} fontSize={"20px"}>
                        Whatsapp Number
                    </Text>
                </Flex>
                {isLoading && <Loader />}
                {!isLoading && (
                    <Tabs bg={"#fff"}>
                        <TabList>
                            <Tab
                                borderBottomColor={active === "pending" ? "grays.8" : "borders.100"}
                                borderBottomWidth={active === "pending" ? 2 : .5}
                                onClick={() => switchTab("pending")}
                                color={active === "pending" ? "grays.8" : "grays.12"}
                                fontWeight={700}
                                fontSize={"12px"}
                            >
                                Pending
                            </Tab>
                            <Tab
                                borderBottomColor={active === "approved" ? "grays.8" : "borders.100"}
                                borderBottomWidth={active === "approved" ? 2 : .5}
                                onClick={() => switchTab("approved")}
                                color={active === "approved" ? "grays.8" : "grays.12"}
                                fontWeight={700}
                                fontSize={"12px"}
                            >
                                Approved
                            </Tab>
                            <Tab
                                borderBottomColor={active === "rejected" ? "grays.8" : "borders.100"}
                                borderBottomWidth={active === "rejected" ? 2 : .5}
                                onClick={() => switchTab("rejected")}
                                color={active === "rejected" ? "grays.8" : "grays.12"}
                                fontWeight={700}
                                fontSize={"12px"}
                            >
                                Rejected
                            </Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Box h="60vh" overflowY={"auto"}>
                                    <WhatsappNumberTable
                                        templateData={data?.docs}
                                        globalFilter={globalFilter}
                                        setGlobalFilter={setGlobalFilter}
                                        dataToShow={dataToShow}
                                        setDataToShow={setDataToShow}
                                    />
                                </Box>
                            </TabPanel>
                            <TabPanel>
                                <Box h="60vh" overflowY={"auto"}>
                                    <WhatsappNumberTable
                                        templateData={data?.docs}
                                        globalFilter={globalFilter}
                                        setGlobalFilter={setGlobalFilter}
                                        dataToShow={dataToShow}
                                        setDataToShow={setDataToShow}
                                    />
                                </Box>
                            </TabPanel>
                            <TabPanel>
                                <Box h="60vh" overflowY={"auto"}>
                                    <WhatsappNumberTable
                                        templateData={data?.docs}
                                        globalFilter={globalFilter}
                                        setGlobalFilter={setGlobalFilter}
                                        dataToShow={dataToShow}
                                        setDataToShow={setDataToShow}
                                    />
                                </Box>
                            </TabPanel>
                            <Box py={4}>
                                <CustomPagination
                                    pagesCount={pagesCount}
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                    pages={pages}
                                    numberOfdataToShow={dataToShow}
                                    setDataToShow={setDataToShow}
                                />
                            </Box>
                        </TabPanels>
                    </Tabs>
                )}
            </Box>
        </>
    );
};

export default WhatsappNumbers;
