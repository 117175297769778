import React from "react";

const OverviewIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5 12.4071C4.65833 12.4071 4.375 12.1237 4.375 11.7821V8.20703C4.375 7.86536 4.65833 7.58203 5 7.58203C5.34167 7.58203 5.625 7.86536 5.625 8.20703V11.7821C5.625 12.1321 5.34167 12.4071 5 12.4071Z"
                fill="currentColor"
            />
            <path
                d="M7.5 13.6004C7.15833 13.6004 6.875 13.3171 6.875 12.9754V7.02539C6.875 6.68372 7.15833 6.40039 7.5 6.40039C7.84167 6.40039 8.125 6.68372 8.125 7.02539V12.9754C8.125 13.3254 7.84167 13.6004 7.5 13.6004Z"
                fill="currentColor"
            />
            <path
                d="M10 14.7923C9.65833 14.7923 9.375 14.509 9.375 14.1673V5.83398C9.375 5.49232 9.65833 5.20898 10 5.20898C10.3417 5.20898 10.625 5.49232 10.625 5.83398V14.1673C10.625 14.509 10.3417 14.7923 10 14.7923Z"
                fill="currentColor"
            />
            <path
                d="M12.5 13.6004C12.1583 13.6004 11.875 13.3171 11.875 12.9754V7.02539C11.875 6.68372 12.1583 6.40039 12.5 6.40039C12.8417 6.40039 13.125 6.68372 13.125 7.02539V12.9754C13.125 13.3254 12.8417 13.6004 12.5 13.6004Z"
                fill="currentColor"
            />
            <path
                d="M15 12.4071C14.6583 12.4071 14.375 12.1237 14.375 11.7821V8.20703C14.375 7.86536 14.6583 7.58203 15 7.58203C15.3417 7.58203 15.625 7.86536 15.625 8.20703V11.7821C15.625 12.1321 15.3417 12.4071 15 12.4071Z"
                fill="currentColor"
            />
            <path
                d="M12.5003 18.9577H7.50033C2.97533 18.9577 1.04199 17.0243 1.04199 12.4993V7.49935C1.04199 2.97435 2.97533 1.04102 7.50033 1.04102H12.5003C17.0253 1.04102 18.9587 2.97435 18.9587 7.49935V12.4993C18.9587 17.0243 17.0253 18.9577 12.5003 18.9577ZM7.50033 2.29102C3.65866 2.29102 2.29199 3.65768 2.29199 7.49935V12.4993C2.29199 16.341 3.65866 17.7077 7.50033 17.7077H12.5003C16.342 17.7077 17.7087 16.341 17.7087 12.4993V7.49935C17.7087 3.65768 16.342 2.29102 12.5003 2.29102H7.50033Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default OverviewIcon;
