import { Box, Flex, Input, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import BusinessTable from '../../components/Business/BusinessTable';
import { getBusinessData } from '../../API/business';
import { useQuery } from "@tanstack/react-query";
import Loader from '../../components/UI/Loader';
import CustomPagination from '../../components/UI/CustomPagination';
import { usePagination } from '@ajna/pagination';
import useDebounce from '../../utils/useDebounce';

const Business = () => {
    const [myCurrentPage, setMyCurrentPage] = useState(1);
    const [dataToShow, setDataToShow] = useState(10);
    const [filter, setFilter] = useState("");
  const debouncedSearch = useDebounce(filter, 1000);
    const { isLoading, data } = useQuery(
        ["businessData", myCurrentPage, dataToShow, debouncedSearch],
        () => {
            return getBusinessData(myCurrentPage, dataToShow, debouncedSearch);
        }
    );

    const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
        pagesCount: data?.totalPages,
        limits: {
            outer: 3,
            inner: 3,
        },
        initialState: { currentPage: 1 },
    });

    const handlePageChange = (nextPage: number): void => {
         setCurrentPage(nextPage);
         setMyCurrentPage(nextPage);
    };

    return (
        <Box mt={"47px"} pl={"32px"} w="full" maxW={"80vw"} h="full">
            <Flex alignItems={"center"} mb={6}>
                <Text fontWeight={700} fontSize={"20px"}>Businesses</Text>
            </Flex>
            {isLoading && <Loader />}

            {!isLoading && (
                <Box bg={"#fff"} position="relative">
                      {/* search input */}
                    <Input
                        ml="auto"
                        type="search"
                        position={"absolute"}
                        right={"0"}
                        mt={"3"}
                        mr={5}
                        placeholder="Search businesses..."
                        borderRadius={"8px"}
                        borderColor="borders.100"
                        _hover={{
                            borderColor: "borders.100",
                        }}
                        color="grays.9"
                        _placeholder={{
                            color: "grays.12",
                        }}
                        fontWeight={500}
                        h="32px"
                        maxW="205px"
                        w="full"
                        fontSize={"xs"}
                        value={filter ?? ""}
                        onChange={(e) => {
                            setFilter(e.target.value.toString());
                        }}
                    />
                    <Box h='60vh' overflowY={'auto'}>
                        <BusinessTable
                            businessData={data?.docs}
                            // globalFilter={globalFilter}
                            // setGlobalFilter={setGlobalFilter}
                            dataToShow={dataToShow}
                            setDataToShow={setDataToShow}
                        />
                    </Box>
                    <Box py={4}>
                        <CustomPagination
                            pagesCount={pagesCount}
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                            pages={pages}
                            numberOfdataToShow={dataToShow}
                            setDataToShow={setDataToShow}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default Business;