import API from ".";

export const getBusinessData = async (
  page: number,
  limit: number,
  search: string
) => {
  const response = await API.get(
    `/admin/business/?page=${page}&size=${limit}&search=${search}`
  );
  return response.data.data;
};

export const getWhatsappTemplate = async (
  active: string,
  uid: any,
  page: number,
  limit: number
) => {
  const response = await API.get(
    `/admin/business/whatsapp/templates/${uid}/?status=${active}&page=${page}&size=${limit}`
  );
  return response.data.data;
};
export const getWhatsappNumbers = async (
  active: string,
  uid: any,
  page: number,
  limit: number
) => {
  const response = await API.get(
    `/admin/business/whatsapp/numbers/${uid}/?status=${active}&page=${page}&size=${limit}`
  );
  return response.data.data;
};

export const approveWhatsappTemplate = async (id: number) => {
  const response = await API.patch(
    `admin/business/whatsapp/templates/approve/${id}`,
    {
      status: "approved",
    }
  );
  return response.data;
};
export const rejectWhatsappTemplate = async (id: number) => {
  const response = await API.patch(
    `admin/business/whatsapp/templates/reject/${id}`,
    {
      status: "rejected",
    }
  );
  return response.data;
};

export const approveWhatsappNumber = async (id: number) => {
  const response = await API.patch(
    `admin/business/whatsapp/numbers/approve/${id}`,
    {
      status: "approved",
    }
  );
  return response.data;
};
export const rejectWhatsappNumber = async (id: number) => {
  const response = await API.patch(
    `admin/business/whatsapp/numbers/reject/${id}`,
    {
      status: "rejected",
    }
  );
  return response.data;
};
