import { Button, useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { loginForm } from '../API/auth';
import { setCookie } from 'cookies-next';
import AuthLayout from '../components/general/AuthLayout';
import FormInput from '../components/UI/form/FormInput';
import { toaster } from '../utils/toast';
import { DASH_ROUTES } from '../utils/routes';

const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const toast = useToast();

  const { mutate, isLoading } = useMutation(loginForm, {
    onSuccess(data: any) {
      const success = { ...data }
      toaster(toast, success)
      setCookie("BODSQUARE_ADMIN_TOKEN", success?.data?.token)
      window.location.href = DASH_ROUTES?.OVERVIEW;
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors)
    },
  });

  return (
    <AuthLayout
      title='Admin Access'
      description=''
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          mutate({ email, password });
        }}
      >
        <FormInput
          label='Email address'
          type={'email'}
          for='email'
          formControlProps={{
            isRequired: true,
            mt: '32px',
          }}
          inputProps={{
            placeholder: 'Enter your email address',
            onChange: (e) => {
              setEmail(e.target.value);
            },
          }}
        />

        <FormInput
          label='Password'
          type={'password'}
          for='password'
          formControlProps={{
            isRequired: true,
            mt: '32px',
          }}
          inputProps={{
            placeholder: 'Enter your password',
            onChange: (e) => {
              setPassword(e.target.value);
            },
          }}
        />

        <Button
          isDisabled={!email || !password}
          isLoading={isLoading}
          type='submit'
          w='full'
          colorScheme={'primary'}
          mt='40px'
        >
          Continue
        </Button>
      </form>
    </AuthLayout>
  );
};

export default Login;
