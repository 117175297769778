import React, { FC, useMemo, useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  chakra,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  HStack,
  VStack,
  Box,
  useDisclosure,
  Button,
  Flex,
  useToast,
  Tooltip,
} from "@chakra-ui/react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import SortArrowUp from "../../../icons/SortArrowUp";
import SortArrowDown from "../../../icons/SortArrowDown";
import CustomModal from "../../UI/CustomModal";
import ChakraSelect from "../../UI/form/ChakraSelect";
import { fuzzyFilter } from "../../../utils/table";
import {
  approveWhatsappTemplate,
  rejectWhatsappTemplate,
} from "../../../API/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toaster } from "../../../utils/toast";
import { removeTags } from "../../../utils/functions";

export interface whatsappTemplateProps {
  id: number;
  uid: string;
  company_uid: string;
  name: string;
}

const WhatsappTemplateTable: FC<{
  templateData: whatsappTemplateProps[];
  globalFilter?: any;
  setGlobalFilter?: any;
  dataToShow?: any;
  setDataToShow?: any;
}> = ({
  templateData,
  globalFilter,
  setGlobalFilter,
  dataToShow,
  setDataToShow,
}) => {
  const [dataToUpdate, setDataToUpdate] = useState<whatsappTemplateProps>({
    id: 0,
    uid: "",
    company_uid: "",
    name: "",
  });
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const rejectDisclosure = useDisclosure();
  const approveDisclosure = useDisclosure();
  const queryClient = useQueryClient();

  const toast = useToast();

  const { isLoading: approving, mutate: approveTemplate } = useMutation(
    approveWhatsappTemplate,
    {
      onSuccess(data) {
        const success = { ...data };
        toaster(toast, success);
        approveDisclosure.onClose();
        queryClient.invalidateQueries(["whatsappTemplateData"]);
      },
      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
    }
  );

  const { isLoading: rejecting, mutate: rejectTemplate } = useMutation(
    rejectWhatsappTemplate,
    {
      onSuccess(data) {
        const success = { ...data };
        toaster(toast, success);
        rejectDisclosure.onClose();
        queryClient.invalidateQueries(["whatsappTemplateData"]);
      },
      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
    }
  );
  // accessor functions are for when you need to concatenate data e.g firstname and lastname
  const columns = useMemo<ColumnDef<whatsappTemplateProps>[]>(() => {
    return [
      {
        header: () => <Text>Template Name</Text>,
        accessorKey: "name",
        id: "name",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: () => <Text>Preview</Text>,
        accessorKey: "template_body",
        id: "template_body",
        cell: (props: any) => {
          return (
            <Tooltip
              placement="bottom-start"
              label={removeTags(props.getValue())}
              closeOnClick={false}
            >
              <Text
                cursor={"pointer"}
                overflow={"hidden"}
                whiteSpace={"nowrap"}
                textOverflow={"ellipsis"}
                maxW={"350px"}
              >
                {removeTags(props?.getValue())}
              </Text>
            </Tooltip>
          );
        },
      },
      {
        header: () => <Text>Provider reference</Text>,
        accessorKey: "provider_identifier",
        id: "provider_identifier",
        cell: (props: any) => {
          return <Text>{props?.getValue() || "—"}</Text>;
        },
      },
      {
        header: () => <Text>Language</Text>,
        accessorKey: "language",
        id: "language",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: "Options",
        id: "actions",
        cell: (props: any) =>
          props?.row?.original.status === "pending" && (
            <HStack>
              <Text
                onClick={() => {
                  approveDisclosure.onOpen();
                  setDataToUpdate(props?.row?.original);
                }}
                cursor={"pointer"}
                color={"primary.500"}
                textDecoration={"underline"}
                fontWeight={"medium"}
              >
                Approve
              </Text>
              <Text
                onClick={() => {
                  rejectDisclosure.onOpen();
                  setDataToUpdate(props?.row?.original);
                }}
                cursor={"pointer"}
                color={"danger.200"}
                textDecoration={"underline"}
                fontWeight={"medium"}
              >
                Reject
              </Text>
            </HStack>
          ),
      },
    ];
  }, [rejectDisclosure, approveDisclosure]);

  const data = templateData;
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,

    debugTable: true,
  });

  return (
    <>
      <TableContainer
        borderWidth={"1px"}
        borderColor="borders.200"
        bg={"#fff"}
        rounded={"8px"}
      >
        {/* filters */}
        <Flex rounded="4px" py="12px" px="20px">
          {/* toggle no of entries shown */}
          <HStack spacing={"10px"}>
            <Text color="grays.12" fontWeight={500} fontSize="xs">
              Showing
            </Text>

            <ChakraSelect
              selectProps={{
                h: "24px",
                borderColor: "grays.12",
                fontWeight: 500,
                color: "grays.12",
                value: Number(dataToShow),
                onChange: (e) => {
                  setDataToShow(Number(e.target.value));
                },
              }}
            >
              <chakra.option value={10}>10</chakra.option>
              <chakra.option value={20}>20</chakra.option>
              <chakra.option value={25}>25</chakra.option>
              <chakra.option value={30}>30</chakra.option>
              <chakra.option value={50}>50</chakra.option>
            </ChakraSelect>

            <Text color="grays.12" fontWeight={500} fontSize="xs">
              entries
            </Text>
          </HStack>
        </Flex>
        <Table variant={"simple"}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        textTransform="none"
                        color={"grays.12"}
                        fontSize="xs"
                        letterSpacing={"unset"}
                        fontWeight={500}
                        colSpan={header.colSpan}
                      >
                        <Box display={"flex"}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}

                          {header.column.getCanSort() && (
                            <VStack spacing="2px" my="auto" ml="2">
                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowUp width="8px" />
                              </chakra.button>

                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowDown width="8px" />
                              </chakra.button>
                            </VStack>
                          )}
                        </Box>
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    fontSize="xs"
                    color="grays.12"
                    fontWeight={"medium"}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {/* Approve whatsapp template*/}
      <CustomModal disclosure={approveDisclosure}>
        <Box mt="42px" pb="40px" textAlign={"center"} w="full">
          <Text
            w={"365px"}
            px={12}
            color="grays.8"
            fontSize={"20px"}
            fontWeight="bold"
          >
            Do you want to Approve this Template?
          </Text>
          <Button
            w="full"
            isLoading={approving}
            onClick={() => {
              approveTemplate(dataToUpdate?.id);
            }}
            bg="primary.500"
            colorScheme={"text.100"}
            mt="40px"
          >
            Approve
          </Button>
        </Box>
      </CustomModal>

      {/* Reject whatsapp template */}
      <CustomModal disclosure={rejectDisclosure}>
        <Box mt="42px" pb="40px" textAlign={"center"} w="full">
          <Text color="grays.8" fontSize={"20px"} fontWeight="bold">
            Are you sure you want to Reject this Template?
          </Text>
          <Button
            w="full"
            isLoading={rejecting}
            onClick={() => {
              rejectTemplate(dataToUpdate?.id);
            }}
            bg="danger.300"
            colorScheme={"text.100"}
            mt="40px"
          >
            Reject
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};

export default WhatsappTemplateTable;
