import {
  UseDisclosureProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  chakra,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

const CustomModal: FC<{
  disclosure: UseDisclosureProps;
  children: ReactNode;
  title?: ReactNode;
}> = ({ disclosure, children, title }) => {
  const { isOpen } = disclosure;

  return (
    <Modal
      // @ts-ignore
      isOpen={isOpen}
      // @ts-ignore
      onClose={disclosure?.onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader p={0} display='flex'>
          {title}

          <chakra.button
            mt='35px'
            px='42px'
            ml='auto'
            onClick={disclosure?.onClose}
            textAlign='right'
            color='primary.500'
            fontSize={'xs'}
            textDecor='underline'
            fontWeight={'bold'}
          >
            Cancel
          </chakra.button>
        </ModalHeader>
        <ModalBody px={{ base: '10px', md: '40px' }} pb={0}>
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
