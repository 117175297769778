import API from ".";
import { smsDataProps } from "../components/SMS/SmsTable";

export const getSmsData = async (page: number, limit: number) => {
  const response = await API.get(
    `/admin/sms-pricing/?page=${page}&size=${limit}`
  );
  return response.data.data;
};

export const addSmsPrice = async (data: {
  country_uid: string;
  amount: number;
}) => {
  const response = await API.post(`/admin/sms-pricing`, {
    country_uid: data.country_uid,
    amount: data.amount,
  });
  return response.data;
};

export const updateSmsPrice = async (data: smsDataProps) => {
  const response = await API.patch(`/admin/sms-pricing/${data?.id}`, {
    country_uid: data?.country_uid,
    amount: data?.amount,
  });
  return response.data;
};

export const deleteSmsPrice = async (id: number) => {
  const response = await API.delete(`/admin/sms-pricing/${id}`);
  return response.data;
};
