import React, { useState } from "react";
import {
    Box,
    Flex,
    Text,
    Button,
    useDisclosure,
    chakra,
    useToast,
} from "@chakra-ui/react";
import SmsTable from "../../components/SMS/SmsTable";
import Back from "../../components/UI/Back";
import CustomModal from "../../components/UI/CustomModal";
// import dayjs from "dayjs";
import FormInput from "../../components/UI/form/FormInput";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addSmsPrice, getSmsData } from "../../API/sms";
import Loader from "../../components/UI/Loader";
import { getCountries } from "../../API/dashboard";
import ChakraSelect from "../../components/UI/form/ChakraSelect";
import CustomPagination from "../../components/UI/CustomPagination";
import { usePagination } from "@ajna/pagination";
import { toaster } from "../../utils/toast";

const SmsPricing = () => {
    const disclosure = useDisclosure();
    const [myCurrentPage, setMyCurrentPage] = useState(1);
    const [globalFilter, setGlobalFilter] = useState("");
    const [dataToShow, setDataToShow] = useState(10);
    const [country, setCountry] = useState<any>("");
    const [amount, setAmount] = useState<any>(0);

    const { data: countryData } = useQuery(["countryData"], getCountries);
    const countriesData = countryData?.countries;

    const { isLoading, data } = useQuery(
        ["smsData", myCurrentPage, dataToShow],
        () => {
            return getSmsData(myCurrentPage, dataToShow);
        }
    );
    const queryClient = useQueryClient();
    const toast = useToast();
    const { isLoading: addingPrice, mutate } = useMutation(addSmsPrice, {
        onSuccess(data) {
            const success = { ...data };
            toaster(toast, success)
            queryClient.invalidateQueries(["smsData"]);
            disclosure.onClose();
            setCountry("");
            setAmount("");
        },

        onError(data: any) {
            const errors = { ...data };
            toaster(toast, errors)
        },
        retry: false,
    });
    const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
        pagesCount: data?.totalPages,
        limits: {
            outer: 3,
            inner: 3,
        },
        initialState: { currentPage: 1 },
    });

    const handlePageChange = (nextPage: number): void => {
        setCurrentPage(nextPage);
        setMyCurrentPage(nextPage);
    };

    return (
        <>
            <Box mt={"47px"} pl={"32px"} w="full" maxW={"80vw"} h="full">
                <Back
                    path="/sms"
                    title="Back"
                    textProps={{
                        fontSize: "12px",
                        color: "grays.12",
                    }}
                />
                <Flex alignItems={"center"} mb={6}>
                    <Text fontWeight={700} fontSize={"20px"}>
                        Sms Pricing
                    </Text>
                    <Button
                        onClick={disclosure.onOpen}
                        colorScheme={"primary"}
                        ml="auto"
                        size={"sm"}
                        fontSize="14px"
                        w={"165px"}
                        px={"60px"}
                        isDisabled={isLoading}
                    >
                        Add New SMS Price
                    </Button>
                </Flex>
                {isLoading && <Loader />}

                {!isLoading && (
                    <Box bg={"#fff"}>
                        <Box h="60vh" overflowY={"auto"}>
                            <SmsTable
                                smsData={data?.docs}
                                globalFilter={globalFilter}
                                setGlobalFilter={setGlobalFilter}
                                dataToShow={dataToShow}
                                setDataToShow={setDataToShow}
                            />
                        </Box>
                        <Box py={4}>
                            <CustomPagination
                                pagesCount={pagesCount}
                                currentPage={currentPage}
                                handlePageChange={handlePageChange}
                                pages={pages}
                                numberOfdataToShow={dataToShow}
                                setDataToShow={setDataToShow}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
            {/* Edit SMS Pricing */}
            <CustomModal
                disclosure={disclosure}
                title={
                    <Text fontSize={"20px"} color="grays.8" mt="35px" px="38px">
                        Add New SMS Price
                    </Text>
                }
            >
                <Box mt="42px" pb="40px" textAlign={"center"} w="full">
                    <Box>
                        <ChakraSelect
                            label="Country"
                            selectProps={{
                                h: "44px",
                                marginTop: "5px",
                                borderColor: "borders.100",
                                color: "grays.9",
                                value: country,
                                placeholder: "Select a country",
                                onChange: (e) => {
                                    setCountry(e.target.value);
                                },
                            }}
                        >
                            {countriesData?.map((country: any) => (
                                <chakra.option key={country?.uid} value={country?.uid}>
                                    {country.name}
                                </chakra.option>
                            ))}
                        </ChakraSelect>
                        <FormInput
                            label="Amount(USD)"
                            type="number"
                            for="amount"
                            inputProps={{
                                placeholder: "Enter amount",
                                marginBottom: "20px",
                                value: amount,
                                onChange: (e) => {
                                    setAmount(e.target.value);
                                },
                            }}
                        />
                    </Box>
                    <Button
                        w="full"
                        isLoading={addingPrice}
                        isDisabled={!country || !amount}
                        colorScheme={"primary"}
                        onClick={() => {
                            mutate({
                                country_uid: country,
                                amount: amount,
                            });
                        }}
                        mt="40px"
                    >
                        Add Pricing
                    </Button>
                </Box>
            </CustomModal>
        </>
    );
};

export default SmsPricing;
