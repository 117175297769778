import API from ".";
export const loginForm = async (data: { email: string; password: string }) => {
  const response = await API.post(`/admin/auth/login`, {
    ...data,
  });

  return response.data;
};

export const updatePassword = async (data: {
  prevPassword: string;
  newPassword: string;
  passwordConfirm: string;
}) => {
  const response = await API.patch(`/admin/auth/change-pwd`, {
    ...data,
  });

  return response.data;
};
