import React, { useState } from 'react'
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Box, Text, chakra, Button, useToast } from "@chakra-ui/react";
import FormInput from '../components/UI/form/FormInput';
import { updatePassword } from '../API/auth';
import { toaster } from '../utils/toast';



const ChangePassword = () => {
    const [prevPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [passwordConfirm, setConfirmPassword] = useState<string>('');

    const navigate = useNavigate();
    const toast = useToast();
    const { mutate, isLoading } = useMutation(updatePassword, {
        onSuccess(data: any) {
            const success = { ...data }
            toaster(toast, success)
            navigate('/overview')
        },

        onError(data: any) {
            const errors = { ...data };
            toaster(toast, errors)
        },
    });

    return (
        <Box color={"black"} mt={"47px"} pl={"32px"}>
            <Text fontWeight={700} fontSize={"20px"}>
                Change Password
            </Text>
            <Text fontWeight={500} mb={"47px"} color={"grays.12"} fontSize={"12px"}>You can change password here and manage the security of your accoount.</Text>
            <Box w={"70vw"} h={"611px"} p={"20px"} bg={"#FFFFFF"} borderRadius={"4px"}>
                <chakra.form
                    w={"360px"}
                    onSubmit={(e) => {
                        e.preventDefault();
                        mutate({ prevPassword, newPassword, passwordConfirm });
                    }}
                >
                    <FormInput
                        label='Current Password'
                        type={'password'}
                        for='current password'
                        formControlProps={{
                            isRequired: true,
                            mt: '32px',
                        }}
                        inputProps={{
                            placeholder: 'Enter your current password',
                            onChange: (e) => {
                                setCurrentPassword(e.target.value);
                            },
                        }}
                    />
                    <FormInput
                        label='New Password'
                        type={'password'}
                        for='new password'
                        formControlProps={{
                            isRequired: true,
                            mt: '32px',
                        }}
                        inputProps={{
                            placeholder: 'Enter your new password',
                            onChange: (e) => {
                                setNewPassword(e.target.value);
                            },
                        }}
                    />
                    <FormInput
                        label='Confirm Password'
                        type={'password'}
                        for='confirm password'
                        formControlProps={{
                            isRequired: true,
                            mt: '32px',
                        }}
                        inputProps={{
                            placeholder: 'Confirm your password',
                            onChange: (e) => {
                                setConfirmPassword(e.target.value);
                            },
                        }}
                    />

                    <Button
                        isDisabled={!prevPassword || !newPassword || !passwordConfirm || newPassword !== passwordConfirm}
                        isLoading={isLoading}
                        type='submit'
                        w='full'
                        colorScheme={'primary'}
                        mt='40px'
                    >
                        Update Password
                    </Button>
                </chakra.form>
            </Box>
        </Box>
    )
}

export default ChangePassword