import React, { FC, useMemo } from "react";

import {
    Table,
    Thead,
    Tbody,
    chakra,
    Tr,
    Th,
    Td,
    TableContainer,
    Text,
    HStack,
    VStack,
    Box,
    Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import SortArrowUp from "../../icons/SortArrowUp";
import SortArrowDown from "../../icons/SortArrowDown";
import ChakraSelect from "../UI/form/ChakraSelect";
import { fuzzyFilter } from "../../utils/table";
import CustomMenu from "../UI/CustomMenu";
import ChevronDown from "../../icons/ChevronDown";
import { useSetRecoilState } from "recoil";
import { globalUidAtom } from "../../atoms/global";
// import { toaster } from "../../../utils/toast";

export interface businessDataProps {
    is_active: boolean;
    id: number;
    uid: string;
    company_uid: string;
    name: string;
    email: string;
    first_name: string;
    last_name: string;
    is_owner: boolean;
    date_added: Date;
    created_at: Date;
    updated_at: Date;
}

const BusinessTable: FC<{
    businessData: businessDataProps[];
    globalFilter?: any;
    setGlobalFilter?: any;
    dataToShow?: any;
    setDataToShow?: any;
}> = ({ businessData, globalFilter, setGlobalFilter, dataToShow, setDataToShow }) => {
    const [sorting, setSorting] = React.useState<SortingState>([]);

    const setGlobalUid = useSetRecoilState<any>(globalUidAtom);

    const navigate = useNavigate();

    // accessor functions are for when you need to concatenate data e.g firstname and lastname
    const columns = useMemo<ColumnDef<businessDataProps>[]>(() => {
        return [
            {
                header: () => <Text>Business Name</Text>,
                accessorKey: "name",
                id: "name",
                cell: (props: any) => {
                    return <Text>{props?.getValue()}</Text>;
                },
            },
            {
                header: () => <Text>Business Email</Text>,
                accessorKey: "email",
                id: "email",
                cell: (props: any) => {
                    return <Text>{props?.getValue()}</Text>;
                },
            },
            {
                header: () => <Text>Phone number</Text>,
                accessorKey: "phone_number",
                id: "phone_number",
                cell: (props: any) => {
                    return <Text>{props?.getValue()}</Text>;
                },
            },
            {
                header: () => <Text>Industry</Text>,
                accessorKey: "industry",
                id: "industry",
                cell: (props: any) => {
                    return <Text>{props?.getValue() || "—"}</Text>;
                },
            },
            {
                header: "Options",
                accessorKey: "uid",
                id: "actions",
                cell: (props: any) => (
                    <CustomMenu
                        buttonTitle={
                            <HStack spacing={"14px"}>
                                <Text fontWeight={"medium"}>View actions</Text>
                                <ChevronDown width="14px" height="14px" />
                            </HStack>
                        }
                        itemsData={[
                            {
                                title: "View whatsapp number",
                                itemProps: {
                                    fontWeight: "medium",
                                    onClick: () => {
                                        setGlobalUid(props?.getValue());
                                        navigate(`/business/whatsapp-number/${props?.getValue()}`);
                                    },
                                },
                            },
                            {
                                title: "View whatsapp templates",
                                itemProps: {
                                    fontWeight: "medium",
                                    onClick: () => {
                                        setGlobalUid(props?.getValue());
                                        navigate(`/business/whatsapp-templates/${props?.getValue()}`);
                                    },
                                },
                            },
                        ]}
                    />
                ),
            },
        ];
    }, [navigate, setGlobalUid]);

    const data = businessData;
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            globalFilter,
        },
        globalFilterFn: fuzzyFilter,
        onSortingChange: setSorting,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onGlobalFilterChange: setGlobalFilter,

        debugTable: true,
    });
    return (
        <>
            <TableContainer
                borderWidth={"1px"}
                borderColor="borders.200"
                bg={"#fff"}
                rounded={"8px"}
            >
                {/* filters */}
                <Flex rounded="4px" py="12px" px="20px">
                    {/* toggle no of entries shown */}
                    <HStack spacing={"10px"}>
                        <Text color="grays.12" fontWeight={500} fontSize="xs">
                            Showing
                        </Text>

                        <ChakraSelect
                            selectProps={{
                                h: "24px",
                                borderColor: "grays.12",
                                fontWeight: 500,
                                color: "grays.12",
                                value: Number(dataToShow),
                                onChange: (e) => {
                                    setDataToShow(Number(e.target.value));
                                },
                            }}
                        >
                            <chakra.option value={10}>10</chakra.option>
                            <chakra.option value={20}>20</chakra.option>
                            <chakra.option value={25}>25</chakra.option>
                            <chakra.option value={30}>30</chakra.option>
                            <chakra.option value={50}>50</chakra.option>
                        </ChakraSelect>

                        <Text color="grays.12" fontWeight={500} fontSize="xs">
                            entries
                        </Text>
                    </HStack>

                    {/* search input */}
                    {/* <Input
                        ml="auto"
                        type="search"
                        placeholder="Search by Business email..."
                        borderRadius={"8px"}
                        borderColor="borders.100"
                        _hover={{
                            borderColor: "borders.100",
                        }}
                        color="grays.9"
                        _placeholder={{
                            color: "grays.12",
                        }}
                        fontWeight={500}
                        h="32px"
                        maxW="205px"
                        w="full"
                        fontSize={"xs"}
                        value={globalFilter ?? ""}
                        onChange={(e) => {
                            setGlobalFilter(e.target.value.toString());
                        }}
                    /> */}
                </Flex>
                <Table variant={"simple"}>
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => {
                            return (
                                <Tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <Th
                                                key={header.id}
                                                textTransform="none"
                                                color={"grays.12"}
                                                fontSize="xs"
                                                letterSpacing={"unset"}
                                                fontWeight={500}
                                                colSpan={header.colSpan}
                                            >
                                                <Box display={"flex"}>
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}

                                                    {header.column.getCanSort() && (
                                                        <VStack spacing="2px" my="auto" ml="2">
                                                            <chakra.button
                                                                onClick={() => {
                                                                    header.column.toggleSorting();
                                                                }}
                                                            >
                                                                <SortArrowUp width="8px" />
                                                            </chakra.button>

                                                            <chakra.button
                                                                onClick={() => {
                                                                    header.column.toggleSorting();
                                                                }}
                                                            >
                                                                <SortArrowDown width="8px" />
                                                            </chakra.button>
                                                        </VStack>
                                                    )}
                                                </Box>
                                            </Th>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row) => (
                            <Tr key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <Td
                                        key={cell.id}
                                        fontSize="xs"
                                        color="grays.12"
                                        fontWeight={"medium"}
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    );
};

export default BusinessTable;
