import { Box, chakra, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { hasCookie } from 'cookies-next';
import { useEffect } from 'react';

const NotFound = () => {
  const auth = hasCookie('BODSQUARE_ADMIN_TOKEN')
  const navigate = useNavigate();
  useEffect(() => {
    auth === false && navigate('/login');
  }, [auth, navigate]);

  return (
    <Flex
      mt='24px'
      bg='white'
      pl={"32x"}
      maxW='1360px'
      mx='auto'
    >
      <Box
        m='auto'
        flexDir='column'
        h='50vh'
      >
        <chakra.h1
          textAlign={'center'}
          fontSize={'64px'}
          color='grays.12'
          fontWeight={700}
        >
          404
        </chakra.h1>

        <Text
          textAlign={'center'}
          mt='10px'
          fontSize={'20px'}
          color='grays.8'
          fontWeight={700}
        >
          Page not found...
        </Text>
        <Text
          textAlign={'center'}
          mt='12px'
          fontSize={'sm'}
          fontWeight={500}
          color='grays.12'
        >
          It looks like we couldn't find the the page you were looking for. You
          can go{' '}
          <Text
            as='span'
            cursor={'pointer'}
            onClick={() => {
              navigate(-1);
            }}
            color='primary.500'
            textDecor={'underline'}
          >
            back
          </Text>
          , or select one of the navigations to redirect to.
        </Text>
      </Box>
    </Flex>
  );
};

export default NotFound;
