import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getDashboardData } from "../../API/dashboard";
import Loader from "../../components/UI/Loader";


const Overview = () => {
    const { isLoading, data } = useQuery(["dashboardData"], getDashboardData);
    return (
        <>
            <Box mt={"47px"} pl={"32px"} w="full" maxW={"80vw"} h="full">
                {isLoading && <Loader />}
                {!isLoading &&
                    <Box color={"black"} mt={"47px"} pl={"32px"}>
                        <Text fontWeight={700} fontSize={"20px"} mb={"47px"}>
                            Hi {data?.admin?.first_name} {data?.admin?.last_name}, Welcome Back
                        </Text>
                        <HStack p={"20px"} alignItems={"center"} bg={"#FFFFFF"} h={"98px"} borderRadius={"4px"}>
                            <Box w={"35vw"}>
                                <Text fontWeight={500} fontSize={"14px"} color={"grays.12"}>Users</Text>
                                <Text fontWeight={700} fontSize={"32px"}>{data?.users}</Text>
                            </Box>
                            <Box w={"35vw"}>
                                <Text fontWeight={500} fontSize={"14px"} color={"grays.12"}>All businessess</Text>
                                <Text fontWeight={700} fontSize={"32px"}>{data?.businesses}</Text>
                            </Box>{" "}
                        </HStack>
                    </Box>
                }
            </Box>
        </>
    );
};

export default Overview;
