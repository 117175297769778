import React from 'react'
import { Link } from "react-router-dom";
import { Box, Button, Divider, Text } from "@chakra-ui/react";

const SMS = () => {
    return (
        <Box color={"black"} mt={"47px"} pl={"32px"}>
            <Text fontWeight={500} fontSize={"20px"} mb={"47px"}>
                SMS
            </Text>
            <Box p={"20px"} bg={"#FFFFFF"} h={"387px"} w={"576px"} borderRadius={"4px"}>
                <Box>
                    <Text fontWeight={500} fontSize={"16px"}>Here are back-office actions under SMS channel</Text>
                </Box>
                <Divider mb={8} mt={5} />
                <Box bg={"#F0F5FB"} w={"528px"} h={"144px"} mb={4}></Box>
                <Box>
                    <Text fontWeight={500} fontSize={"16px"}>SMS Pricing</Text>
                    <Text fontWeight={500} fontSize={"12px"} color={"grays.12"}>Manage SMS pricing based on country and set priority</Text>
                </Box>
                <Link to={"/sms/pricing"}>
                    <Button mt={"23px"} colorScheme="primary" size="sm">
                        Get Started
                    </Button>
                </Link>
            </Box>
        </Box>
    )
}

export default SMS