import { chakra } from "@chakra-ui/react";
import { FC } from "react";

const FullLogo: FC<any> = (props) => {
  return (
    <chakra.svg
      {...props}
      width="169px"
      height="32px"
      viewBox="0 0 169 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1431_21393)">
        <path
          d="M41.8867 21.4851V5.61352H47.7735C48.4741 5.60843 49.1729 5.68444 49.855 5.83994C50.4853 5.98758 51.0887 6.22813 51.6441 6.55321C52.2075 6.8866 52.6617 7.36876 52.9536 7.9434C53.2881 8.60612 53.4538 9.33715 53.4368 10.075C53.4368 11.4757 52.7905 12.5023 51.4979 13.1545C53.3961 13.8185 54.3452 15.0386 54.3452 16.8148C54.3478 17.2171 54.3098 17.6186 54.2317 18.0137C54.1304 18.4457 53.9595 18.8594 53.7255 19.2397C53.4702 19.6819 53.1239 20.0684 52.7083 20.3753C52.2904 20.6881 51.7101 20.9501 50.9676 21.1613C50.1184 21.3885 49.2401 21.4964 48.3594 21.4815L41.8867 21.4851ZM44.8307 12.0834H47.9414C49.6399 12.0834 50.4892 11.4445 50.4892 10.1665C50.4892 8.76421 49.5678 8.06306 47.7252 8.06306H44.8259L44.8307 12.0834ZM44.8307 18.9616H48.2132C50.3345 18.9616 51.3952 18.1952 51.3952 16.6622C51.3952 15.2912 50.2983 14.6053 48.1045 14.6045H44.8307V18.9616Z"
          fill="#0066FF"
        />
        <path
          d="M57.0581 20.0914C56.0618 19.0379 55.5633 17.5273 55.5625 15.5595C55.5617 13.5917 56.0602 12.0811 57.0581 11.0276C58.0551 9.97413 59.485 9.44739 61.3478 9.44739C63.2106 9.44739 64.6401 9.97413 65.6364 11.0276C66.6334 12.0811 67.1319 13.5913 67.1319 15.5583C67.1319 17.5253 66.6334 19.0359 65.6364 20.0902C64.6401 21.1437 63.2106 21.67 61.3478 21.6693C59.485 21.6685 58.0551 21.1425 57.0581 20.0914ZM59.206 12.5738C58.7396 13.0924 58.5065 14.0895 58.5065 15.5654C58.5065 17.0412 58.7396 18.0364 59.206 18.551C59.6723 19.0664 60.3882 19.3241 61.3538 19.3241C62.3195 19.3241 63.0334 19.0664 63.4957 18.551C63.9572 18.0364 64.1883 17.0412 64.1891 15.5654C64.1899 14.0895 63.9588 13.0924 63.4957 12.5738C63.0318 12.0553 62.3179 11.7956 61.3538 11.7949C60.3898 11.7941 59.6739 12.0537 59.206 12.5738Z"
          fill="#0066FF"
        />
        <path
          d="M68.5209 15.4082C68.5209 13.4764 69.0073 11.9962 69.9802 10.9678C70.953 9.93933 72.3528 9.4251 74.1793 9.4251C74.8618 9.42781 75.5426 9.48984 76.2137 9.61045V5.04456H79.1564V17.6688C79.1564 18.7638 78.732 19.7023 77.8832 20.4844C77.0343 21.2665 75.7361 21.6599 73.9884 21.6646C73.5819 21.6648 73.1759 21.6338 72.7744 21.5719C72.2809 21.4791 71.8047 21.3142 71.3622 21.0827C70.834 20.827 70.3576 20.4809 69.956 20.0609C69.5533 19.6424 69.2143 19.0277 68.9388 18.2167C68.6453 17.3083 68.5041 16.36 68.5209 15.4082ZM71.4636 15.2568C71.4636 16.6552 71.6513 17.6653 72.0266 18.2871C72.4019 18.9088 73.0558 19.2217 73.9884 19.2256C75.4719 19.2256 76.2137 18.7982 76.2137 17.9433V11.8488C75.5781 11.7184 74.9304 11.6519 74.2808 11.6506C73.3627 11.6506 72.6644 11.9169 72.186 12.4495C71.7077 12.9821 71.4669 13.9179 71.4636 15.2568Z"
          fill="#0066FF"
        />
        <path
          d="M80.834 13.1393C80.834 11.945 81.2665 11.028 82.1314 10.3883C82.9964 9.74851 84.0752 9.42863 85.3678 9.42863C86.5383 9.41785 87.6905 9.71066 88.7056 10.2768V12.4471C87.82 12.0553 86.8554 11.8605 85.8824 11.877C84.4786 11.877 83.7768 12.211 83.7768 12.8789C83.7768 13.2132 83.9761 13.4748 84.3808 13.666C84.8488 13.8799 85.3377 14.0477 85.8401 14.167C86.4284 14.3161 87.003 14.5123 87.5579 14.7536C88.1572 15.0224 88.6638 15.4533 89.0173 15.9947C89.4199 16.5766 89.6213 17.3196 89.6213 18.2237C89.6213 19.2178 89.2045 20.0421 88.371 20.6967C87.5374 21.3514 86.4349 21.6783 85.0633 21.6775C83.7037 21.6886 82.3648 21.3529 81.1807 20.7038V18.1709C82.1974 18.8521 83.4029 19.2178 84.6381 19.2197C85.4837 19.2197 86.0342 19.1243 86.2895 18.9335C86.5448 18.7427 86.6724 18.4572 86.6724 18.0771C86.6724 17.7275 86.4711 17.4459 86.0684 17.2324C85.6043 16.995 85.115 16.8074 84.6091 16.6728C84.0389 16.5164 83.4687 16.3279 82.8985 16.1074C82.3199 15.884 81.8153 15.5111 81.4392 15.0292C81.0349 14.5287 80.8332 13.8987 80.834 13.1393Z"
          fill="#0066FF"
        />
        <path
          d="M91.5893 15.7108C91.572 14.7569 91.7115 13.8064 92.0025 12.8952C92.2771 12.0865 92.6158 11.473 93.0184 11.0545C93.7814 10.2877 94.7635 9.76055 95.838 9.54117C96.2375 9.47927 96.6415 9.44828 97.046 9.44849C98.7905 9.44849 100.089 9.83954 100.942 10.6216C101.795 11.4037 102.221 12.345 102.22 13.4454V25.8878H99.2821V21.5296C98.616 21.6718 97.9358 21.7426 97.2538 21.7408C95.4249 21.7408 94.0235 21.2207 93.0498 20.1805C92.0762 19.1403 91.5893 17.6504 91.5893 15.7108ZM94.5321 15.8504C94.5321 17.2136 94.781 18.1642 95.2787 18.7023C95.7764 19.2404 96.4984 19.5094 97.4447 19.5094C98.0597 19.5042 98.6736 19.458 99.2821 19.371V13.1779C99.2821 12.3262 98.55 11.9 97.0859 11.8992H97.0509C96.1191 11.8992 95.4663 12.2093 95.0926 12.8295C94.719 13.4497 94.5321 14.4567 94.5321 15.8504Z"
          fill="#0066FF"
        />
        <path
          d="M104.691 16.1062V9.61047H107.637V16.4722C107.637 17.4225 107.838 18.1064 108.241 18.5241C108.643 18.9417 109.295 19.1501 110.195 19.1494C111.115 19.1494 111.775 18.9409 112.174 18.5241C112.573 18.1072 112.775 17.4233 112.778 16.4722V9.61047H115.722V16.1062C115.722 17.8487 115.235 19.2119 114.262 20.1958C113.288 21.1797 111.934 21.6712 110.2 21.6705C108.465 21.6705 107.116 21.1789 106.151 20.1958C105.186 19.2127 104.699 17.8495 104.691 16.1062Z"
          fill="#0066FF"
        />
        <path
          d="M117.483 17.6042C117.483 17.3566 117.508 17.1096 117.555 16.8663C117.634 16.5348 117.762 16.216 117.934 15.9196C118.142 15.5361 118.427 15.1964 118.77 14.9201C119.123 14.6338 119.647 14.3878 120.341 14.1822C121.135 13.964 121.957 13.8605 122.782 13.8748C123.501 13.8783 124.219 13.9328 124.93 14.0379C124.93 12.5268 124.067 11.7713 122.341 11.7713C120.963 11.7713 119.766 11.9727 118.749 12.3755V10.0292C119.955 9.61393 121.228 9.40938 122.508 9.42503C124.253 9.42503 125.587 9.85518 126.512 10.7155C127.436 11.5758 127.898 12.8248 127.897 14.4625V17.6699C127.902 18.1988 127.792 18.7227 127.575 19.2077C127.358 19.6928 127.039 20.1281 126.637 20.4855C125.796 21.2715 124.504 21.6645 122.759 21.6645C120.955 21.6645 119.626 21.2695 118.773 20.4796C118.361 20.1159 118.033 19.6717 117.811 19.1761C117.588 18.6805 117.477 18.1448 117.483 17.6042ZM120.427 17.2452C120.427 18.5154 121.194 19.1504 122.729 19.1504H122.753C124.217 19.1504 124.948 18.5502 124.948 17.3497V15.8504C124.367 15.7657 123.782 15.7152 123.194 15.699C121.36 15.699 120.437 16.2144 120.427 17.2452Z"
          fill="#0066FF"
        />
        <path
          d="M130.094 21.485V10.6674C130.293 10.5759 130.567 10.4668 130.919 10.3401C131.614 10.1228 132.321 9.94229 133.037 9.79928C134.073 9.56771 135.131 9.44228 136.194 9.42505V11.8769C134.967 11.8926 133.914 12.0244 133.037 12.2723V21.485H130.094Z"
          fill="#0066FF"
        />
        <path
          d="M137.234 15.6756C137.234 13.7704 137.67 12.2485 138.543 11.1097C139.416 9.97098 140.802 9.40161 142.702 9.40161C144.497 9.40161 145.854 9.95534 146.775 11.0628C147.696 12.1702 148.156 13.5334 148.157 15.1524C148.157 15.8187 148.141 16.3107 148.109 16.6282H140.177C140.257 17.6582 140.575 18.3477 141.129 18.6965C141.683 19.0453 142.402 19.2197 143.287 19.2197C144.729 19.2187 146.151 18.9048 147.452 18.3011V20.6017C146.095 21.3149 144.56 21.6712 142.845 21.6704C142.332 21.673 141.819 21.6302 141.313 21.5425C140.791 21.4425 140.289 21.2623 139.825 21.0088C139.321 20.7459 138.87 20.3952 138.496 19.974C138.127 19.5564 137.823 18.9776 137.585 18.2378C137.336 17.4058 137.218 16.542 137.234 15.6756ZM140.177 14.6538H145.107C145.054 13.9129 144.796 13.1998 144.359 12.5902C144.166 12.3207 143.907 12.1025 143.604 11.9556C143.302 11.8086 142.967 11.7376 142.629 11.7491C141.895 11.7491 141.323 11.9775 140.912 12.4342C140.502 12.8909 140.258 13.6308 140.18 14.6538H140.177Z"
          fill="#0066FF"
        />
        <path
          d="M15.7329 18.8173C13.7776 18.8176 11.8534 18.342 10.1361 17.4342C9.78622 17.2486 9.5266 16.9356 9.41434 16.5641C9.30208 16.1927 9.34639 15.7931 9.5375 15.4533C9.72861 15.1136 10.0509 14.8614 10.4334 14.7524C10.8159 14.6434 11.2274 14.6864 11.5773 14.872C12.8527 15.5458 14.2816 15.8988 15.7335 15.8988C17.1855 15.8988 18.6143 15.5458 19.8898 14.872C20.2397 14.6864 20.6511 14.6434 21.0337 14.7524C21.4162 14.8614 21.7385 15.1136 21.9296 15.4533C22.1207 15.7931 22.165 16.1927 22.0527 16.5641C21.9405 16.9356 21.6809 17.2486 21.331 17.4342C19.6133 18.3422 17.6886 18.8177 15.7329 18.8173Z"
          fill="#0066FF"
        />
        <path
          d="M8.01264 32C7.66072 31.9993 7.31304 31.9253 6.99306 31.783C6.56298 31.5977 6.19796 31.2946 5.9428 30.9107C5.68765 30.5268 5.55345 30.0789 5.5567 29.6221C5.55651 29.562 5.56055 29.5021 5.56878 29.4426L6.02904 25.8351C6.08146 25.4538 6.28677 25.108 6.60034 24.873C6.9139 24.6379 7.31036 24.5326 7.70347 24.5799C8.09658 24.6272 8.45456 24.8234 8.69955 25.1256C8.94454 25.4279 9.05672 25.8119 9.01169 26.1941L8.77009 28.0712L11.8578 24.9975C11.9955 24.8601 12.1598 24.7503 12.3411 24.6746C12.5225 24.5988 12.7175 24.5584 12.9149 24.5558C13.1123 24.5532 13.3084 24.5884 13.4918 24.6593C13.6752 24.7303 13.8425 24.8356 13.984 24.9693C14.1255 25.1031 14.2385 25.2625 14.3165 25.4387C14.3946 25.6148 14.4361 25.8042 14.4388 25.9959C14.4415 26.1876 14.4053 26.378 14.3322 26.5561C14.2592 26.7343 14.1507 26.8967 14.013 27.0341L9.69786 31.3313C9.66887 31.3595 9.63987 31.3865 9.60847 31.4123C9.16787 31.7902 8.6005 31.9992 8.01264 32Z"
          fill="#0066FF"
        />
        <path
          d="M25.3193 27.474H12.9393C12.5408 27.474 12.1585 27.3202 11.8767 27.0465C11.5948 26.7729 11.4365 26.4016 11.4365 26.0146C11.4365 25.6275 11.5948 25.2563 11.8767 24.9826C12.1585 24.709 12.5408 24.5552 12.9393 24.5552H25.3193C26.1517 24.5533 26.9495 24.2314 27.5382 23.6598C28.1268 23.0881 28.4583 22.3134 28.4602 21.505V5.9725C28.4583 5.16412 28.1268 4.38937 27.5382 3.81775C26.9495 3.24613 26.1517 2.92417 25.3193 2.92232H6.15013C5.3177 2.92417 4.51991 3.24613 3.93129 3.81775C3.34267 4.38937 3.01114 5.16412 3.00923 5.9725V21.5015C3.01114 22.3099 3.34267 23.0846 3.93129 23.6562C4.51991 24.2279 5.3177 24.5498 6.15013 24.5517H7.52367C7.92224 24.5517 8.30449 24.7054 8.58632 24.9791C8.86815 25.2528 9.02648 25.624 9.02648 26.0111C9.02648 26.3981 8.86815 26.7693 8.58632 27.043C8.30449 27.3167 7.92224 27.4705 7.52367 27.4705H6.15013C4.52053 27.4677 2.95843 26.838 1.80578 25.7193C0.653136 24.6006 0.00383185 23.084 0 21.5015L0 5.9725C0.00287435 4.38936 0.651756 2.87185 1.80451 1.75239C2.95726 0.632932 4.5199 0.00279134 6.15013 0L25.3193 0C26.9496 0.00279134 28.5122 0.632932 29.6649 1.75239C30.8177 2.87185 31.4666 4.38936 31.4694 5.9725V21.5015C31.4666 23.0846 30.8177 24.6021 29.6649 25.7216C28.5122 26.8411 26.9496 27.4712 25.3193 27.474Z"
          fill="#0066FF"
        />
        <path
          d="M105.797 28.9463V29.4965H104.971V24.5587H105.797V26.1906C106.048 25.8281 106.565 25.577 107.126 25.577C108.254 25.577 109.037 26.3583 109.037 27.5643C109.037 28.7703 108.254 29.5528 107.119 29.5528C106.558 29.5528 106.048 29.3018 105.797 28.9463ZM106.995 28.8618C107.707 28.8618 108.203 28.3386 108.203 27.5643C108.203 26.79 107.708 26.261 106.995 26.261C106.283 26.261 105.797 26.7912 105.797 27.5643C105.797 28.3374 106.292 28.8595 106.995 28.8595V28.8618Z"
          fill="#0066FF"
        />
        <path
          d="M110.472 29.4683L108.914 25.6333H109.804L110.892 28.4489L111.979 25.6333H112.847L110.778 30.7658H109.917L110.472 29.4683Z"
          fill="#0066FF"
        />
        <path
          d="M129.785 28.2412H130.589C130.625 28.653 130.934 28.897 131.415 28.897C131.846 28.897 132.112 28.6941 132.112 28.3738C132.112 27.5444 129.893 28.0735 129.893 26.6587C129.893 26.0451 130.452 25.577 131.278 25.577C132.19 25.577 132.758 26.017 132.829 26.7502H132.038C131.981 26.3982 131.716 26.2058 131.292 26.2058C130.929 26.2058 130.688 26.3666 130.688 26.6246C130.688 27.3708 132.921 26.8264 132.921 28.3468C132.921 29.0718 132.303 29.547 131.42 29.547C130.474 29.5528 129.835 29.0366 129.785 28.2412Z"
          fill="#0066FF"
        />
        <path
          d="M133.117 27.569C133.117 26.4052 133.942 25.5817 135.078 25.5817C136.327 25.5817 137.103 26.5202 136.944 27.7156H133.935C133.986 28.4547 134.452 28.8806 135.098 28.8806C135.623 28.8806 135.99 28.6365 136.083 28.2459H136.929C136.764 28.9779 136.125 29.5575 135.097 29.5575C133.885 29.5528 133.117 28.7292 133.117 27.569ZM136.097 27.0997C136.083 26.5554 135.673 26.214 135.078 26.214C134.482 26.214 134.101 26.5284 133.971 27.0997H136.097Z"
          fill="#0066FF"
        />
        <path
          d="M137.391 25.6333H138.216V26.2198C138.457 25.8573 138.97 25.5781 139.545 25.5781C140.39 25.5781 141.032 26.1225 141.032 26.9448V29.4976H140.205V27.1396C140.205 26.6234 139.874 26.2891 139.36 26.2891C138.693 26.2891 138.218 26.7912 138.218 27.7602V29.4964H137.393L137.391 25.6333Z"
          fill="#0066FF"
        />
        <path
          d="M141.426 27.569C141.426 26.363 142.209 25.5817 143.336 25.5817C143.896 25.5817 144.413 25.8328 144.665 26.1953V24.5587H145.491V29.4965H144.665V28.9463C144.413 29.2982 143.903 29.5528 143.343 29.5528C142.209 29.5528 141.426 28.7715 141.426 27.569ZM143.465 28.8595C144.169 28.8595 144.665 28.3363 144.665 27.562C144.665 26.7877 144.169 26.2586 143.465 26.2586C142.761 26.2586 142.257 26.7889 142.257 27.562C142.257 28.3351 142.755 28.8595 143.465 28.8595Z"
          fill="#0066FF"
        />
        <path
          d="M145.914 27.569C145.914 26.4052 146.76 25.5817 147.904 25.5817C148.902 25.5817 149.613 26.119 149.728 26.9894H148.894C148.801 26.5566 148.411 26.2644 147.904 26.2644C147.243 26.2644 146.748 26.7947 146.748 27.5678C146.748 28.3409 147.25 28.8723 147.904 28.8723C148.45 28.8723 148.859 28.5591 148.923 28.1403H149.769C149.675 28.9498 148.964 29.5563 147.909 29.5563C146.755 29.5528 145.914 28.7222 145.914 27.569Z"
          fill="#0066FF"
        />
        <path
          d="M150.121 24.5587H150.946V26.2187C151.098 26.016 151.299 25.8519 151.53 25.7402C151.761 25.6285 152.017 25.5725 152.275 25.577C153.121 25.577 153.762 26.1214 153.762 26.9437V29.4965H152.937V27.1396C152.937 26.6235 152.606 26.2891 152.091 26.2891C151.425 26.2891 150.95 26.7912 150.95 27.7673V29.4965H150.125L150.121 24.5587Z"
          fill="#0066FF"
        />
        <path
          d="M154.193 28.4501C154.193 27.7462 154.71 27.3062 155.874 27.1807L156.829 27.0763V26.9719C156.829 26.5026 156.467 26.2187 155.939 26.2187C155.411 26.2187 155.062 26.4979 155.005 26.9649H154.201C154.308 26.1706 154.998 25.577 155.939 25.577C156.937 25.577 157.647 26.1214 157.647 27.0622V29.4965H156.829V28.9252C156.649 29.3018 156.147 29.5528 155.572 29.5528C154.725 29.5528 154.193 29.127 154.193 28.4501ZM155.679 28.9193C156.355 28.9193 156.829 28.4794 156.829 27.7122V27.6863L155.888 27.7908C155.299 27.8529 155.02 28.0829 155.02 28.4254C155.02 28.7152 155.291 28.9182 155.679 28.9182V28.9193Z"
          fill="#0066FF"
        />
        <path
          d="M164.333 26.9438V29.4965H163.507V27.1326C163.507 26.6235 163.205 26.2891 162.724 26.2891C162.092 26.2891 161.697 26.8605 161.697 27.8142V29.4954H160.871V27.1326C160.871 26.6235 160.576 26.2891 160.095 26.2891C159.464 26.2891 159.068 26.8534 159.068 27.8142V29.4954H158.242V25.6333H159.068V26.2117C159.27 25.8703 159.744 25.577 160.312 25.577C160.9 25.577 161.36 25.8562 161.553 26.3654C161.693 26.1265 161.896 25.9277 162.141 25.7893C162.385 25.6508 162.663 25.5776 162.946 25.577C163.751 25.5747 164.333 26.1214 164.333 26.9438Z"
          fill="#0066FF"
        />
        <path
          d="M164.936 25.6333H165.762V26.1906C166.013 25.8281 166.53 25.577 167.091 25.577C168.218 25.577 169.001 26.3583 169.001 27.5643C169.001 28.7703 168.218 29.5528 167.083 29.5528C166.523 29.5528 166.013 29.3018 165.762 28.9463V30.7659H164.936V25.6333ZM166.961 28.8595C167.672 28.8595 168.169 28.3363 168.169 27.562C168.169 26.7877 167.673 26.2586 166.961 26.2586C166.25 26.2586 165.762 26.7889 165.762 27.562C165.762 28.3351 166.257 28.8595 166.961 28.8595Z"
          fill="#0066FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.255 26.2645C120.351 26.1964 121.55 26.0299 121.68 25.9994C122.026 25.9219 122.804 25.8246 123.172 25.7729C123.293 25.7553 123.239 25.7506 123.335 25.7307C123.879 25.6134 125.823 25.3893 126.062 25.2837C126.08 24.2279 126.182 23.8161 125.274 23.9933L122.087 24.6538C121.597 24.7558 119.241 25.2075 118.892 25.3119C118.508 25.4292 118.364 25.638 118.365 26.0592V27.0998C118.581 27.1922 118.803 27.2706 119.03 27.3344L120.371 27.7861C120.605 27.8576 120.821 27.955 121.025 28.0207C121.344 28.1239 123.58 28.8419 123.695 28.9264C123.637 28.9686 123.567 28.9913 123.495 28.9909C123.415 29.0026 123.335 29.0085 123.253 29.0179L121.956 29.2032C121.045 29.3288 116.737 29.9153 116.696 29.9552C116.632 30.0631 116.662 30.5125 116.658 30.679C116.639 31.4627 117.229 31.2469 118.229 31.0721C118.66 30.997 119.134 30.9289 119.558 30.8691C120.388 30.7518 121.353 30.5453 122.143 30.4397L124.123 30.1253C124.861 29.9611 125.518 30.1148 125.519 29.2748V28.0735C125.309 27.9793 125.093 27.8982 124.872 27.8306C124.657 27.7544 124.42 27.6734 124.224 27.6101C123.752 27.4564 123.333 27.3086 122.895 27.1655C122.683 27.0951 122.442 27.0024 122.229 26.9308C122.015 26.8593 121.777 26.7936 121.571 26.7126C121.261 26.5883 120.502 26.3795 120.255 26.261V26.2645Z"
          fill="#0066FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M127.151 27.921C127.118 27.817 127.07 27.7182 127.008 27.6277C126.464 26.4545 126.365 26.9613 127.209 26.2199C127.374 26.0744 127.802 25.7694 127.838 25.6462L125.121 26.0439C125.001 26.0099 122.858 26.3161 122.592 26.3959L122.756 26.4768C123.609 26.7478 126.941 27.9268 127.151 27.9304V27.921Z"
          fill="#0066FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.492 27.1513C116.506 27.2769 117.076 28.2494 117.071 28.3245C117.065 28.4313 115.849 29.2736 115.781 29.4578L117.179 29.2654C117.404 29.2313 117.649 29.1915 117.864 29.1633C118.951 29.019 120 28.897 121.065 28.694C120.539 28.4804 119.999 28.3006 119.449 28.1556L119.264 28.0734C118.359 27.8048 117.4 27.4435 116.486 27.1478L116.492 27.1513Z"
          fill="#0066FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1431_21393">
          <rect width="169" height="32" fill="white" />
        </clipPath>
      </defs>
    </chakra.svg>
  );
};

const IconLogo: FC<any> = (props) => {
  return (
    <chakra.svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1163_21799)">
        <rect width="32" height="32" rx="4.5" fill="#0066FF" />
        <path
          d="M13.4609 16.0786C14.2198 16.4927 15.0894 16.729 16.0151 16.729C16.9408 16.729 17.8105 16.494 18.5694 16.0786"
          stroke="white"
          strokeWidth="1.57406"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5514 21.3965L12.2907 23.7144C11.9716 23.9921 11.4746 23.7656 11.4746 23.3429L11.7158 21.3977"
          stroke="white"
          strokeWidth="1.57406"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5516 21.3965H21.0351C22.3786 21.3965 23.4687 20.3063 23.4687 18.9628V10.5877C23.4687 9.24419 22.3786 8.15405 21.0351 8.15405H10.9961C9.65264 8.15405 8.5625 9.24419 8.5625 10.5877V18.9628C8.5625 20.3063 9.65264 21.3965 10.9961 21.3965H11.716"
          stroke="white"
          strokeWidth="1.57406"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1163_21799">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </chakra.svg>
  );
};

const SidebarLogo: FC<any> = (props) => {
  return (
    <svg
      width="127"
      height="25"
      viewBox="0 0 127 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.4775 16.1151V4.21112H35.9013C36.4278 4.2073 36.9529 4.26431 37.4655 4.38094C37.9392 4.49167 38.3926 4.67209 38.81 4.91591C39.2334 5.16596 39.5747 5.52758 39.7941 5.95858C40.0454 6.45563 40.17 7.00392 40.1572 7.55733C40.1572 8.60792 39.6715 9.37782 38.7001 9.86704C40.1266 10.3651 40.8399 11.2801 40.8399 12.6123C40.8418 12.914 40.8133 13.2152 40.7545 13.5115C40.6784 13.8355 40.55 14.1458 40.3742 14.431C40.1823 14.7627 39.9221 15.0526 39.6098 15.2828C39.2957 15.5174 38.8596 15.7139 38.3016 15.8723C37.6635 16.0427 37.0034 16.1236 36.3416 16.1125L31.4775 16.1151ZM33.6899 9.0637H36.0275C37.3039 9.0637 37.9421 8.58446 37.9421 7.62596C37.9421 6.5742 37.2498 6.04832 35.865 6.04832H33.6863L33.6899 9.0637ZM33.6899 14.2225H36.2318C37.8259 14.2225 38.623 13.6476 38.623 12.4979C38.623 11.4696 37.7987 10.9552 36.1501 10.9546H33.6899V14.2225Z"
        fill="white"
      />
      <path
        d="M42.8778 15.0692C42.1291 14.279 41.7545 13.146 41.7539 11.6702C41.7533 10.1943 42.1279 9.06129 42.8778 8.27115C43.627 7.48101 44.7016 7.08594 46.1015 7.08594C47.5013 7.08594 48.5756 7.48101 49.3242 8.27115C50.0735 9.06129 50.4481 10.194 50.4481 11.6693C50.4481 13.1446 50.0735 14.2776 49.3242 15.0683C48.5756 15.8584 47.5013 16.2532 46.1015 16.2526C44.7016 16.252 43.627 15.8575 42.8778 15.0692ZM44.4919 9.43084C44.1415 9.81975 43.9663 10.5677 43.9663 11.6746C43.9663 12.7815 44.1415 13.5279 44.4919 13.9139C44.8423 14.3004 45.3803 14.4937 46.106 14.4937C46.8316 14.4937 47.3682 14.3004 47.7156 13.9139C48.0623 13.5279 48.236 12.7815 48.2366 11.6746C48.2372 10.5677 48.0636 9.81975 47.7156 9.43084C47.367 9.04193 46.8304 8.84718 46.106 8.84659C45.3816 8.84601 44.8435 9.04076 44.4919 9.43084Z"
        fill="white"
      />
      <path
        d="M51.4922 11.5561C51.4922 10.1073 51.8578 8.99713 52.5889 8.22576C53.3199 7.45439 54.3718 7.06871 55.7444 7.06871C56.2573 7.07074 56.7689 7.11727 57.2732 7.20773V3.7832H59.4846V13.2517C59.4846 14.0729 59.1657 14.7768 58.5278 15.3634C57.8899 15.95 56.9143 16.2451 55.601 16.2486C55.2955 16.2488 54.9904 16.2255 54.6886 16.1791C54.3178 16.1095 53.96 15.9858 53.6274 15.8122C53.2305 15.6204 52.8725 15.3608 52.5707 15.0458C52.2681 14.7319 52.0133 14.2709 51.8063 13.6626C51.5857 12.9813 51.4796 12.2701 51.4922 11.5561ZM53.7037 11.4426C53.7037 12.4915 53.8447 13.249 54.1267 13.7154C54.4087 14.1817 54.9002 14.4164 55.601 14.4193C56.7158 14.4193 57.2732 14.0987 57.2732 13.4576V8.88656C56.7956 8.7887 56.3088 8.73887 55.8207 8.73785C55.1307 8.73785 54.606 8.93759 54.2465 9.33706C53.887 9.73653 53.7061 10.4384 53.7037 11.4426Z"
        fill="white"
      />
      <path
        d="M60.7451 9.85361C60.7451 8.95789 61.0701 8.27011 61.7201 7.79028C62.3701 7.31044 63.1808 7.07053 64.1522 7.07053C65.0318 7.06244 65.8977 7.28205 66.6605 7.70669V9.33448C65.995 9.04056 65.2701 8.89446 64.5389 8.90685C63.484 8.90685 62.9566 9.15733 62.9566 9.65828C62.9566 9.90905 63.1064 10.1053 63.4105 10.2487C63.7622 10.4091 64.1296 10.535 64.5071 10.6244C64.9492 10.7362 65.381 10.8834 65.798 11.0643C66.2483 11.266 66.6291 11.5892 66.8947 11.9953C67.1973 12.4317 67.3486 12.9889 67.3486 13.667C67.3486 14.4126 67.0354 15.0309 66.409 15.5219C65.7826 16.0128 64.9541 16.258 63.9234 16.2574C62.9016 16.2658 61.8955 16.014 61.0057 15.5271V13.6275C61.7697 14.1384 62.6756 14.4126 63.6038 14.4141C64.2393 14.4141 64.653 14.3425 64.8448 14.1994C65.0367 14.0563 65.1326 13.8421 65.1326 13.5571C65.1326 13.2949 64.9813 13.0837 64.6787 12.9235C64.3299 12.7455 63.9622 12.6048 63.5821 12.5038C63.1536 12.3865 62.7251 12.2452 62.2966 12.0797C61.8618 11.9122 61.4826 11.6325 61.1999 11.2711C60.8961 10.8957 60.7445 10.4232 60.7451 9.85361Z"
        fill="white"
      />
      <path
        d="M68.8282 11.7847C68.8152 11.0693 68.92 10.3564 69.1387 9.673C69.3451 9.06647 69.5996 8.60629 69.9022 8.29246C70.4755 7.71731 71.2136 7.32195 72.021 7.1574C72.3213 7.11098 72.6248 7.08774 72.9288 7.08789C74.2397 7.08789 75.2156 7.38119 75.8566 7.96778C76.4975 8.55437 76.8176 9.26034 76.817 10.0857V19.4178H74.6092V16.149C74.1086 16.2556 73.5975 16.3087 73.085 16.3074C71.7106 16.3074 70.6575 15.9173 69.9258 15.1371C69.1941 14.3569 68.8282 13.2395 68.8282 11.7847ZM71.0397 11.8894C71.0397 12.9119 71.2267 13.6249 71.6007 14.0285C71.9747 14.432 72.5173 14.6338 73.2284 14.6338C73.6906 14.6299 74.1519 14.5952 74.6092 14.53V9.88506C74.6092 9.24626 74.0591 8.92656 72.9588 8.92598H72.9325C72.2322 8.92598 71.7417 9.15856 71.4609 9.62373C71.1801 10.0889 71.0397 10.8441 71.0397 11.8894Z"
        fill="white"
      />
      <path
        d="M78.6738 12.0809V7.20898H80.8871V12.3555C80.8871 13.0682 81.0384 13.5811 81.341 13.8944C81.6436 14.2076 82.1332 14.3639 82.8099 14.3634C83.501 14.3634 83.9967 14.207 84.2969 13.8944C84.597 13.5817 84.7484 13.0687 84.7508 12.3555V7.20898H86.9631V12.0809C86.9631 13.3879 86.5973 14.4103 85.8656 15.1482C85.1339 15.8861 84.1165 16.2548 82.8135 16.2542C81.5099 16.2542 80.4955 15.8856 79.7705 15.1482C79.0454 14.4109 78.6799 13.3884 78.6738 12.0809Z"
        fill="white"
      />
      <path
        d="M88.2853 13.2036C88.2859 13.0179 88.3042 12.8326 88.3398 12.6501C88.3994 12.4015 88.4954 12.1623 88.6249 11.94C88.7812 11.6524 88.9947 11.3977 89.2531 11.1904C89.5182 10.9757 89.9115 10.7912 90.4332 10.6369C91.0298 10.4733 91.648 10.3957 92.2679 10.4064C92.8083 10.4091 93.3478 10.4499 93.882 10.5287C93.882 9.39541 93.2336 8.82876 91.9366 8.82876C90.9011 8.82876 90.0011 8.97981 89.2367 9.2819V7.52213C90.1435 7.21067 91.0999 7.05725 92.0619 7.06898C93.3728 7.06898 94.3756 7.39161 95.0704 8.03686C95.7652 8.68211 96.1122 9.6189 96.1116 10.8472V13.2528C96.1153 13.6495 96.0329 14.0424 95.8698 14.4062C95.7066 14.77 95.4665 15.0965 95.1648 15.3646C94.533 15.9541 93.5616 16.2489 92.2507 16.2489C90.8944 16.2489 89.8958 15.9526 89.2549 15.3602C88.9454 15.0874 88.6989 14.7542 88.5318 14.3825C88.3647 14.0108 88.2807 13.609 88.2853 13.2036ZM90.4977 12.9343C90.4977 13.8869 91.0745 14.3633 92.228 14.3633H92.2462C93.3458 14.3633 93.8957 13.913 93.8957 13.0126V11.8881C93.459 11.8246 93.0188 11.7867 92.5775 11.7746C91.1988 11.7746 90.5056 12.1612 90.4977 12.9343Z"
        fill="white"
      />
      <path
        d="M97.7627 16.1156V8.00211C97.9125 7.93348 98.1186 7.85165 98.3827 7.75663C98.9053 7.59366 99.4365 7.45825 99.9741 7.351C100.753 7.17731 101.548 7.08323 102.347 7.07031V8.90928C101.425 8.92101 100.634 9.01985 99.9741 9.2058V16.1156H97.7627Z"
        fill="white"
      />
      <path
        d="M103.128 11.7584C103.128 10.3294 103.456 9.18793 104.112 8.33385C104.768 7.47977 105.81 7.05273 107.237 7.05273C108.586 7.05273 109.606 7.46804 110.298 8.29866C110.99 9.12927 111.336 10.1517 111.337 11.3659C111.337 11.8657 111.324 12.2347 111.3 12.4728H105.34C105.4 13.2454 105.638 13.7625 106.055 14.0241C106.471 14.2857 107.012 14.4165 107.677 14.4165C108.76 14.4157 109.829 14.1804 110.806 13.7276V15.453C109.787 15.988 108.633 16.2552 107.345 16.2546C106.959 16.2566 106.574 16.2245 106.194 16.1587C105.801 16.0836 105.424 15.9485 105.075 15.7583C104.696 15.5612 104.358 15.2981 104.077 14.9823C103.799 14.669 103.571 14.235 103.392 13.6801C103.205 13.056 103.116 12.4082 103.128 11.7584ZM105.34 10.992H109.044C109.005 10.4363 108.811 9.90143 108.482 9.44427C108.337 9.24214 108.142 9.07849 107.915 8.96827C107.688 8.85806 107.436 8.80481 107.182 8.81339C106.631 8.81339 106.201 8.98468 105.892 9.32725C105.584 9.66982 105.4 10.2247 105.342 10.992H105.34Z"
        fill="white"
      />
      <path
        d="M11.8233 14.1126C10.3539 14.1128 8.90794 13.7561 7.61738 13.0752C7.35446 12.936 7.15936 12.7013 7.07501 12.4227C6.99065 12.144 7.02394 11.8444 7.16756 11.5895C7.31118 11.3347 7.55336 11.1456 7.84082 11.0638C8.12829 10.9821 8.43749 11.0143 8.70041 11.1535C9.65889 11.6589 10.7326 11.9236 11.8238 11.9236C12.9149 11.9236 13.9886 11.6589 14.9471 11.1535C15.21 11.0143 15.5192 10.9821 15.8067 11.0638C16.0942 11.1456 16.3363 11.3347 16.48 11.5895C16.6236 11.8444 16.6569 12.144 16.5725 12.4227C16.4882 12.7013 16.2931 12.936 16.0301 13.0752C14.7393 13.7562 13.293 14.1129 11.8233 14.1126Z"
        fill="white"
      />
      <path
        d="M6.02142 24.0014C5.75695 24.0008 5.49568 23.9453 5.25522 23.8386C4.93203 23.6997 4.65772 23.4723 4.46598 23.1844C4.27423 22.8965 4.17339 22.5606 4.17582 22.2179C4.17568 22.1729 4.17872 22.1279 4.1849 22.0833L4.53078 19.3776C4.57017 19.0916 4.72446 18.8323 4.9601 18.656C5.19574 18.4797 5.49366 18.4007 5.78908 18.4362C6.0845 18.4716 6.35351 18.6187 6.53762 18.8455C6.72172 19.0722 6.80602 19.3602 6.77219 19.6468L6.59062 21.0547L8.91101 18.7494C9.01449 18.6463 9.1379 18.564 9.2742 18.5071C9.4105 18.4503 9.55701 18.42 9.70538 18.4181C9.85374 18.4161 10.0011 18.4425 10.1389 18.4957C10.2767 18.5489 10.4024 18.6279 10.5088 18.7282C10.6151 18.8285 10.7 18.9481 10.7587 19.0803C10.8173 19.2124 10.8485 19.3544 10.8506 19.4982C10.8526 19.642 10.8254 19.7847 10.7705 19.9184C10.7156 20.052 10.634 20.1738 10.5306 20.2768L7.28783 23.4999C7.26604 23.521 7.24425 23.5412 7.22065 23.5606C6.88955 23.844 6.46318 24.0008 6.02142 24.0014Z"
        fill="white"
      />
      <path
        d="M19.027 20.6061H9.72365C9.42414 20.6061 9.13689 20.4908 8.9251 20.2855C8.71331 20.0802 8.59433 19.8018 8.59433 19.5115C8.59433 19.2212 8.71331 18.9428 8.9251 18.7375C9.13689 18.5323 9.42414 18.4169 9.72365 18.4169H19.027C19.6525 18.4155 20.2521 18.1741 20.6944 17.7453C21.1367 17.3166 21.3859 16.7355 21.3873 16.1292V4.47951C21.3859 3.8732 21.1367 3.29212 20.6944 2.8634C20.2521 2.43467 19.6525 2.1932 19.027 2.1918H4.62171C3.99615 2.1932 3.39663 2.43467 2.95429 2.8634C2.51196 3.29212 2.26282 3.8732 2.26138 4.47951V16.1266C2.26282 16.7329 2.51196 17.314 2.95429 17.7427C3.39663 18.1714 3.99615 18.4129 4.62171 18.4143H5.6539C5.95341 18.4143 6.24066 18.5296 6.45245 18.7349C6.66424 18.9402 6.78323 19.2186 6.78323 19.5089C6.78323 19.7992 6.66424 20.0776 6.45245 20.2829C6.24066 20.4881 5.95341 20.6035 5.6539 20.6035H4.62171C3.39709 20.6014 2.22321 20.1291 1.35701 19.2901C0.490819 18.451 0.00287956 17.3135 0 16.1266L0 4.47951C0.00216002 3.29211 0.489782 2.15395 1.35605 1.31433C2.22232 0.474713 3.39662 0.00209356 4.62171 0L19.027 0C20.2521 0.00209356 21.4264 0.474713 22.2926 1.31433C23.1589 2.15395 23.6465 3.29211 23.6487 4.47951V16.1266C23.6465 17.314 23.1589 18.4522 22.2926 19.2918C21.4264 20.1314 20.2521 20.604 19.027 20.6061Z"
        fill="white"
      />
      <path
        d="M79.5047 21.7107V22.1234H78.8838V18.4199H79.5047V19.6438C79.6936 19.372 80.0821 19.1837 80.5033 19.1837C81.3512 19.1837 81.9395 19.7697 81.9395 20.6742C81.9395 21.5787 81.3512 22.1656 80.4979 22.1656C80.0767 22.1656 79.6936 21.9773 79.5047 21.7107ZM80.4053 21.6474C80.94 21.6474 81.3131 21.2549 81.3131 20.6742C81.3131 20.0935 80.9409 19.6966 80.4053 19.6966C79.8697 19.6966 79.5047 20.0944 79.5047 20.6742C79.5047 21.254 79.8769 21.6456 80.4053 21.6456V21.6474Z"
        fill="white"
      />
      <path
        d="M83.0168 22.101L81.8457 19.2246H82.5148L83.3318 21.3363L84.1488 19.2246H84.8016L83.2465 23.0741H82.5992L83.0168 22.101Z"
        fill="white"
      />
      <path
        d="M97.5322 21.1818H98.1359C98.1632 21.4907 98.3956 21.6737 98.7569 21.6737C99.081 21.6737 99.2807 21.5215 99.2807 21.2812C99.2807 20.6592 97.613 21.056 97.613 19.9949C97.613 19.5347 98.0333 19.1836 98.6543 19.1836C99.3397 19.1836 99.7664 19.5136 99.8199 20.0635H99.2253C99.1826 19.7995 98.9829 19.6552 98.6643 19.6552C98.3919 19.6552 98.2104 19.7758 98.2104 19.9693C98.2104 20.5289 99.8889 20.1207 99.8889 21.261C99.8889 21.8048 99.4241 22.1611 98.7605 22.1611C98.0497 22.1655 97.5694 21.7784 97.5322 21.1818Z"
        fill="white"
      />
      <path
        d="M100.035 20.678C100.035 19.8052 100.655 19.1875 101.509 19.1875C102.447 19.1875 103.03 19.8914 102.911 20.788H100.65C100.688 21.3423 101.038 21.6617 101.524 21.6617C101.918 21.6617 102.194 21.4787 102.264 21.1857H102.899C102.776 21.7348 102.296 22.1694 101.523 22.1694C100.613 22.1659 100.035 21.5482 100.035 20.678ZM102.275 20.3261C102.264 19.9178 101.956 19.6618 101.509 19.6618C101.061 19.6618 100.774 19.8976 100.677 20.3261H102.275Z"
        fill="white"
      />
      <path
        d="M103.246 19.2249H103.866V19.6649C104.048 19.393 104.433 19.1836 104.865 19.1836C105.5 19.1836 105.982 19.5919 105.982 20.2087V22.1233H105.361V20.3547C105.361 19.9676 105.113 19.7168 104.726 19.7168C104.225 19.7168 103.868 20.0934 103.868 20.8202V22.1224H103.248L103.246 19.2249Z"
        fill="white"
      />
      <path
        d="M106.278 20.6777C106.278 19.7732 106.867 19.1872 107.714 19.1872C108.135 19.1872 108.523 19.3755 108.712 19.6474V18.4199H109.333V22.1234H108.712V21.7107C108.523 21.9747 108.14 22.1656 107.719 22.1656C106.867 22.1656 106.278 21.5796 106.278 20.6777ZM107.811 21.6456C108.34 21.6456 108.712 21.2532 108.712 20.6724C108.712 20.0917 108.34 19.6949 107.811 19.6949C107.281 19.6949 106.903 20.0926 106.903 20.6724C106.903 21.2523 107.277 21.6456 107.811 21.6456Z"
        fill="white"
      />
      <path
        d="M109.651 20.678C109.651 19.8052 110.287 19.1875 111.147 19.1875C111.896 19.1875 112.431 19.5905 112.517 20.2434H111.891C111.821 19.9187 111.528 19.6996 111.147 19.6996C110.65 19.6996 110.278 20.0973 110.278 20.6772C110.278 21.257 110.655 21.6556 111.147 21.6556C111.557 21.6556 111.865 21.4207 111.913 21.1065H112.548C112.477 21.7137 111.944 22.1686 111.15 22.1686C110.283 22.1659 109.651 21.543 109.651 20.678Z"
        fill="white"
      />
      <path
        d="M112.813 18.4199H113.434V19.665C113.548 19.5129 113.698 19.3898 113.872 19.3061C114.046 19.2223 114.238 19.1803 114.432 19.1837C115.068 19.1837 115.55 19.5919 115.55 20.2087V22.1234H114.93V20.3557C114.93 19.9685 114.681 19.7178 114.294 19.7178C113.793 19.7178 113.436 20.0943 113.436 20.8264V22.1234H112.816L112.813 18.4199Z"
        fill="white"
      />
      <path
        d="M115.873 21.3384C115.873 20.8105 116.262 20.4805 117.136 20.3864L117.854 20.3081V20.2298C117.854 19.8778 117.582 19.6649 117.185 19.6649C116.788 19.6649 116.526 19.8743 116.483 20.2245H115.878C115.959 19.6288 116.478 19.1836 117.185 19.1836C117.935 19.1836 118.468 19.5919 118.468 20.2975V22.1233H117.854V21.6948C117.719 21.9772 117.341 22.1655 116.909 22.1655C116.272 22.1655 115.873 21.8461 115.873 21.3384ZM116.99 21.6904C117.497 21.6904 117.854 21.3604 117.854 20.785V20.7656L117.147 20.8439C116.704 20.8906 116.494 21.063 116.494 21.32C116.494 21.5373 116.698 21.6895 116.99 21.6895V21.6904Z"
        fill="white"
      />
      <path
        d="M123.493 20.2087V22.1233H122.872V20.3503C122.872 19.9685 122.645 19.7177 122.284 19.7177C121.809 19.7177 121.512 20.1462 121.512 20.8615V22.1224H120.891V20.3503C120.891 19.9685 120.67 19.7177 120.309 19.7177C119.834 19.7177 119.537 20.1409 119.537 20.8615V22.1224H118.916V19.2258H119.537V19.6596C119.689 19.4036 120.044 19.1836 120.471 19.1836C120.913 19.1836 121.259 19.393 121.404 19.7749C121.51 19.5957 121.662 19.4466 121.846 19.3428C122.029 19.239 122.238 19.184 122.451 19.1836C123.056 19.1818 123.493 19.5919 123.493 20.2087Z"
        fill="white"
      />
      <path
        d="M123.945 19.2258H124.566V19.6438C124.755 19.3719 125.144 19.1836 125.565 19.1836C126.412 19.1836 127 19.7696 127 20.6741C127 21.5786 126.412 22.1655 125.559 22.1655C125.138 22.1655 124.755 21.9772 124.566 21.7106V23.0753H123.945V19.2258ZM125.468 21.6455C126.002 21.6455 126.376 21.2531 126.376 20.6724C126.376 20.0916 126.002 19.6948 125.468 19.6948C124.933 19.6948 124.566 20.0925 124.566 20.6724C124.566 21.2522 124.938 21.6455 125.468 21.6455Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.3706 19.6981C90.4433 19.6471 91.3438 19.5222 91.4419 19.4993C91.7015 19.4412 92.2861 19.3682 92.563 19.3295C92.6538 19.3163 92.6129 19.3128 92.6856 19.2978C93.0941 19.2098 94.5548 19.0417 94.7345 18.9626C94.7481 18.1707 94.8253 17.8618 94.1426 17.9947L91.7478 18.4901C91.3792 18.5666 89.609 18.9054 89.3466 18.9837C89.0579 19.0717 88.9499 19.2283 88.9508 19.5442V20.3246C89.113 20.3939 89.2798 20.4527 89.4501 20.5006L90.4578 20.8394C90.6339 20.893 90.7964 20.9661 90.9498 21.0153C91.1895 21.0928 92.8699 21.6313 92.9561 21.6946C92.9127 21.7263 92.8598 21.7433 92.8054 21.743C92.7455 21.7518 92.6856 21.7562 92.6238 21.7632L91.6488 21.9023C90.9643 21.9964 87.7271 22.4364 87.6962 22.4663C87.6481 22.5472 87.6708 22.8842 87.6681 23.0092C87.6535 23.5969 88.0965 23.435 88.8482 23.3039C89.1723 23.2476 89.5282 23.1966 89.8468 23.1517C90.4705 23.0637 91.1958 22.9089 91.7896 22.8297L93.2775 22.5939C93.8321 22.4707 94.326 22.5859 94.3269 21.9559V21.0549C94.1688 20.9843 94.0063 20.9235 93.8403 20.8728C93.6787 20.8156 93.5008 20.7549 93.3537 20.7074C92.9988 20.5921 92.6838 20.4812 92.3551 20.3739C92.1953 20.3211 92.0147 20.2516 91.854 20.1979C91.6933 20.1442 91.5145 20.095 91.3602 20.0343C91.1268 19.941 90.5567 19.7844 90.3706 19.6955V19.6981Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.5531 20.9424C95.5285 20.8645 95.4924 20.7904 95.4459 20.7225C95.0365 19.8426 94.9621 20.2227 95.5966 19.6666C95.721 19.5575 96.0424 19.3287 96.0696 19.2363L94.0279 19.5346C93.9371 19.5091 92.3267 19.7387 92.127 19.7986L92.2504 19.8593C92.8913 20.0625 95.3951 20.9468 95.5531 20.9495V20.9424Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.5426 20.364C87.5535 20.4581 87.982 21.1875 87.9783 21.2439C87.9738 21.3239 87.0596 21.9557 87.0088 22.0938L88.0591 21.9495C88.228 21.924 88.4123 21.8941 88.5739 21.873C89.3909 21.7647 90.1789 21.6732 90.9796 21.521C90.5842 21.3608 90.1785 21.2259 89.7649 21.1172L89.626 21.0556C88.9461 20.8541 88.2253 20.5831 87.538 20.3613L87.5426 20.364Z"
        fill="white"
      />
    </svg>
  );
};

export { FullLogo, IconLogo, SidebarLogo };
