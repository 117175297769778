import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuButtonProps,
  MenuItemProps,
  MenuListProps,
  MenuOptionGroup,
  MenuItemOption,
  MenuItemOptionProps,
  MenuOptionGroupProps,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface itemProps {
  title: string | ReactNode;
  itemProps?: MenuItemProps;
  isLink?: boolean;
  href?: string;
}

interface itemSelectProps {
  title: string | ReactNode;
  itemSelectProps?: MenuItemOptionProps;
}

interface Props {
  buttonProps?: MenuButtonProps;
  listProps?: MenuListProps;
  itemsData?: itemProps[];
  itemsSelectData?: itemSelectProps[];
  buttonTitle: string | ReactNode;
  isSelect?: Boolean;
  menuSelectProps?: MenuOptionGroupProps;
}

const CustomMenu: FC<Props> = ({
  buttonProps,
  listProps,
  itemsData,
  itemsSelectData,
  buttonTitle,
  isSelect,
  menuSelectProps,
}) => {
  return (
    <Menu autoSelect={false}>
      <MenuButton {...buttonProps}>{buttonTitle}</MenuButton>
      <MenuList {...listProps}>
        {!isSelect && (
          <>
            {itemsData?.map((data, index) => {
              if (data?.isLink) {
                return (
                  <Link key={index} to={data?.href || ''}>
                    <MenuItem {...data?.itemProps}>
                      {data?.title}
                    </MenuItem>
                  </Link>
                );
              } else {
                return (
                  <MenuItem key={index} {...data?.itemProps}>
                    {data?.title}
                  </MenuItem>
                );
              }
            })}
          </>
        )}

        {isSelect && (
          <MenuOptionGroup {...menuSelectProps} type='radio'>
            {itemsSelectData?.map((data, index) => {
              return (
                <MenuItemOption key={index} {...data?.itemSelectProps}>
                  {data?.title}
                </MenuItemOption>
              );
            })}
          </MenuOptionGroup>
        )}
      </MenuList>
    </Menu>
  );
};

export default CustomMenu;
