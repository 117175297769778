import { Flex } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import Sidebar from './Sidebar';

const LoggedinLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Flex flexDir='row' h='full'>
      <Sidebar />
      {children}
    </Flex>
  );
};

export default LoggedinLayout;
