import { Box } from '@chakra-ui/react';
import { getCookie } from 'cookies-next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AUTH_ROUTES, DASH_ROUTES } from './utils/routes';

const App = () => {
  const loggedIn = !!getCookie('BODSQUARE_ADMIN_TOKEN');
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn) {
      navigate(DASH_ROUTES.OVERVIEW);
    } else {
      navigate(AUTH_ROUTES.LOGIN);
    }
  }, [loggedIn, navigate]);

  return <Box textAlign='center' fontSize='xl'></Box>;
};

export default App;