import React, { FC, useMemo, useState } from "react";

import {
    Table,
    Thead,
    Tbody,
    chakra,
    Tr,
    Th,
    Td,
    TableContainer,
    Text,
    HStack,
    VStack,
    Box,
    useDisclosure,
    Button,
    Flex,
    Input,
    useToast,
} from "@chakra-ui/react";

import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import SortArrowUp from "../../icons/SortArrowUp";
import SortArrowDown from "../../icons/SortArrowDown";
import CustomModal from "../UI/CustomModal";
import { getCountries } from "../../API/dashboard";
import ChakraSelect from "../UI/form/ChakraSelect";
import FormInput from "../UI/form/FormInput";
import { fuzzyFilter } from "../../utils/table";
import { updateSmsPrice, deleteSmsPrice } from "../../API/sms";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { toaster } from "../../utils/toast";
// import { toaster } from "../../../utils/toast";

export interface smsDataProps {
    country_uid: string;
    amount: number;
    id: number;
}

const SmsTable: FC<{
    smsData: smsDataProps[];
    globalFilter: any;
    setGlobalFilter: any;
    dataToShow: any;
    setDataToShow: any;
}> = ({
    smsData,
    globalFilter,
    setGlobalFilter,
    dataToShow,
    setDataToShow,
}) => {
        const [dataToUpdate, setDataToUpdate] = useState<smsDataProps>({
            id: 0,
            amount: 0,
            country_uid: "",
        });

        const [sorting, setSorting] = React.useState<SortingState>([]);
        const editDisclosure = useDisclosure();
        const deleteDisclosure = useDisclosure();
        const queryClient = useQueryClient();
        const { data: countryData } = useQuery(["countryData"], getCountries);
        const countriesData = countryData?.countries;
        const toast = useToast();
        const { isLoading: editingPrice, mutate: editSmsPrice } = useMutation(
            updateSmsPrice,
            {
                onSuccess(data: any) {
                    const success = { ...data };
                    toaster(toast, success)
                    queryClient.invalidateQueries(["smsData"]);
                    editDisclosure.onClose();
                },
                onError(data: any) {
                    const errors = { ...data };
                    toaster(toast, errors);
                },
                retry: false,
            }
        );

        const { isLoading: deletingSmsPrice, mutate: deletePrice } = useMutation(
            deleteSmsPrice,
            {
                onSuccess(data: any) {
                    const success = { ...data };
                    toaster(toast, success)
                    queryClient.invalidateQueries(["smsData"]);
                    deleteDisclosure.onClose();
                },
                onError(data: any) {
                    const errors = { ...data };
                    toaster(toast, errors)
                },
                retry: false,
            }
        );

        // accessor functions are for when you need to concatenate data e.g firstname and lastname
        const columns = useMemo<ColumnDef<smsDataProps>[]>(() => {
            return [
                {
                    header: () => <Text>Country</Text>,
                    accessorKey: "country",
                    id: "country",
                    cell: (props: any) => {
                        return <Text>{props?.getValue()?.name}</Text>;
                    },
                },
                {
                    header: "Amount(USD)",
                    accessorKey: "amount",
                    id: "amount",
                    cell: (props: any) => {
                        return <Text>{props?.getValue()?.toFixed(5)}</Text>;
                    },
                },
                {
                    header: "Options",
                    id: "actions",
                    cell: (props: any) => (
                        <HStack>
                            <Text
                                onClick={() => {
                                    editDisclosure.onOpen();
                                    setDataToUpdate({
                                        id: props?.row?.original?.id,
                                        country_uid: props?.row?.original?.country?.uid,
                                        amount: props?.row?.original?.amount,
                                    });
                                }}
                                cursor={"pointer"}
                                color={"primary.500"}
                                textDecoration={"underline"}
                                fontWeight={"medium"}
                            >
                                Edit
                            </Text>
                            <Text
                                onClick={() => {
                                    deleteDisclosure.onOpen();
                                    setDataToUpdate(props?.row?.original);
                                }}
                                cursor={"pointer"}
                                color={"danger.200"}
                                textDecoration={"underline"}
                                fontWeight={"medium"}
                            >
                                Delete
                            </Text>
                        </HStack>
                    ),
                },
            ];
        }, [editDisclosure, deleteDisclosure]);
        const data = smsData;
        const table = useReactTable({
            data,
            columns,
            state: {
                sorting,
                globalFilter,
            },
            globalFilterFn: fuzzyFilter,
            onSortingChange: setSorting,
            getFilteredRowModel: getFilteredRowModel(),
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
            onGlobalFilterChange: setGlobalFilter,

            debugTable: true,
        });
        return (
            <>
                <TableContainer
                    borderWidth={"1px"}
                    borderColor="borders.200"
                    bg={"#fff"}
                    rounded={"8px"}
                >
                    {/* filters */}
                    <Flex rounded="4px" py="12px" px="20px">
                        {/* toggle no of entries shown */}
                        <HStack spacing={"10px"}>
                            <Text color="grays.12" fontWeight={500} fontSize="xs">
                                Showing
                            </Text>

                            <ChakraSelect
                                selectProps={{
                                    h: "24px",
                                    borderColor: "grays.12",
                                    fontWeight: 500,
                                    color: "grays.12",
                                    value: Number(dataToShow),
                                    onChange: (e) => {
                                        setDataToShow(Number(e.target.value));
                                    },
                                }}
                            >
                                <chakra.option value={10}>10</chakra.option>
                                <chakra.option value={20}>20</chakra.option>
                                <chakra.option value={25}>25</chakra.option>
                                <chakra.option value={30}>30</chakra.option>
                                <chakra.option value={50}>50</chakra.option>
                            </ChakraSelect>

                            <Text color="grays.12" fontWeight={500} fontSize="xs">
                                entries
                            </Text>
                        </HStack>

                        {/* search input */}
                        <Input
                            ml="auto"
                            type="search"
                            placeholder="Search by country.."
                            borderRadius={"8px"}
                            borderColor="borders.100"
                            _hover={{
                                borderColor: "borders.100",
                            }}
                            color="grays.9"
                            _placeholder={{
                                color: "grays.12",
                            }}
                            fontWeight={500}
                            h="32px"
                            maxW="165px"
                            w="full"
                            fontSize={"xs"}
                            value={globalFilter ?? ""}
                            onChange={(e) => {
                                setGlobalFilter(e.target.value.toString());
                            }}
                        />
                    </Flex>
                    <Table variant={"simple"}>
                        <Thead>
                            {table.getHeaderGroups().map((headerGroup) => {
                                return (
                                    <Tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <Th
                                                    key={header.id}
                                                    textTransform="none"
                                                    color={"grays.12"}
                                                    fontSize="xs"
                                                    letterSpacing={"unset"}
                                                    fontWeight={500}
                                                    colSpan={header.colSpan}
                                                >
                                                    <Box display={"flex"}>
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}

                                                        {header.column.getCanSort() && (
                                                            <VStack spacing="2px" my="auto" ml="2">
                                                                <chakra.button
                                                                    onClick={() => {
                                                                        header.column.toggleSorting();
                                                                    }}
                                                                >
                                                                    <SortArrowUp width="8px" />
                                                                </chakra.button>

                                                                <chakra.button
                                                                    onClick={() => {
                                                                        header.column.toggleSorting();
                                                                    }}
                                                                >
                                                                    <SortArrowDown width="8px" />
                                                                </chakra.button>
                                                            </VStack>
                                                        )}
                                                    </Box>
                                                </Th>
                                            );
                                        })}
                                    </Tr>
                                );
                            })}
                        </Thead>
                        <Tbody>
                            {table?.getRowModel()?.rows?.map((row) => (
                                <Tr key={row.id}>
                                    {row.getVisibleCells()?.map((cell) => (
                                        <Td
                                            key={cell.id}
                                            fontSize="xs"
                                            color="grays.12"
                                            fontWeight={"medium"}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </Td>
                                    ))}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>

                {/* Edit SMS Pricing */}
                <CustomModal
                    disclosure={editDisclosure}
                    title={
                        <Text fontSize={"20px"} color="grays.8" mt="35px" px="38px">
                            Edit SMS Price
                        </Text>
                    }
                >
                    <Box mt="42px" pb="40px" textAlign={"center"} w="full">
                        <Box>
                            <ChakraSelect
                                label="Country"
                                selectProps={{
                                    h: "44px",
                                    marginTop: "5px",
                                    borderColor: "borders.100",
                                    color: "grays.9",
                                    value: dataToUpdate?.country_uid,
                                    placeholder: "Select a country",
                                    onChange: (e) => {
                                        setDataToUpdate({ ...dataToUpdate, country_uid: e.target.value });
                                    },
                                }}
                            >
                                {countriesData?.map((country: any) => (
                                    <chakra.option key={country?.uid} value={country?.uid}>
                                        {country.name}
                                    </chakra.option>
                                ))}
                            </ChakraSelect>
                            <FormInput
                                label="Amount(USD)"
                                type="number"
                                for="amount"
                                inputProps={{
                                    placeholder: "Enter amount",
                                    marginBottom: "20px",
                                    value: dataToUpdate?.amount,
                                    onChange: (e) => {
                                        setDataToUpdate({
                                            ...dataToUpdate,
                                            amount: Number(e.target.value),
                                        });
                                    },
                                }}
                            />
                        </Box>
                        <Button
                            w="full"
                            isLoading={editingPrice}
                            isDisabled={!dataToUpdate?.amount || !dataToUpdate?.country_uid}
                            colorScheme={"primary"}
                            onClick={() => {
                                editSmsPrice({
                                    country_uid: dataToUpdate?.country_uid,
                                    amount: +dataToUpdate?.amount,
                                    id: dataToUpdate?.id,
                                });
                            }}
                            mt="40px"
                        >
                            Update Pricing
                        </Button>
                    </Box>
                </CustomModal>
                {/* Delere list */}
                <CustomModal disclosure={deleteDisclosure}>
                    <Box mt="42px" pb="10px" textAlign={"center"} w="full">
                        <Box display={"inline-flex"}>
                            <svg
                                width="80"
                                height="80"
                                viewBox="0 0 80 80"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    opacity="0.4"
                                    d="M70.2992 71.6641C70.2992 72.5974 69.5659 73.3307 68.6325 73.3307H11.3659C10.4326 73.3307 9.69922 72.5974 9.69922 71.6641C9.69922 57.8641 23.2992 46.6641 39.9992 46.6641C43.4325 46.6641 46.7659 47.1306 49.8326 48.0306C47.8659 50.364 46.6659 53.3974 46.6659 56.6641C46.6659 59.1641 47.3658 61.5307 48.5992 63.5307C49.2658 64.6641 50.1325 65.6973 51.1325 66.564C53.4659 68.6973 56.5659 69.9974 59.9992 69.9974C63.7325 69.9974 67.0992 68.464 69.4992 65.9974C70.0326 67.7974 70.2992 69.6974 70.2992 71.6641Z"
                                    fill="#7489AA"
                                />
                                <path
                                    d="M72.9331 53.4698C72.5998 52.1698 72.0664 50.8697 71.3331 49.7031C70.8331 48.8364 70.1664 48.0031 69.4331 47.2364C67.0331 44.8364 63.8998 43.5365 60.6998 43.3699C57.0664 43.1365 53.3665 44.4698 50.5665 47.2364C47.9332 49.8698 46.5998 53.3698 46.6664 56.8698C46.6998 60.2032 48.0332 63.5366 50.5665 66.1032C52.3332 67.8699 54.4998 69.0365 56.7998 69.5698C58.0665 69.9032 59.3998 70.0364 60.7331 69.9364C63.8998 69.8031 66.9997 68.5366 69.4331 66.1032C72.8664 62.6699 74.0331 57.8364 72.9331 53.4698ZM65.3331 62.0031C64.3665 62.9698 62.7665 62.9698 61.7998 62.0031L59.9664 60.1698L58.1998 61.9366C57.2331 62.9032 55.6331 62.9032 54.6664 61.9366C53.6998 60.9366 53.6998 59.3699 54.6664 58.4032L56.4331 56.6365L54.7332 54.9698C53.7665 53.9698 53.7665 52.4031 54.7332 51.4031C55.7332 50.4364 57.2997 50.4364 58.2997 51.4031L59.9664 53.1031L61.7331 51.3366C62.6997 50.3699 64.2664 50.3699 65.2664 51.3366C66.2331 52.3033 66.2331 53.9032 65.2664 54.8699L63.4997 56.6365L65.3331 58.4698C66.2998 59.4364 66.2998 61.0364 65.3331 62.0031Z"
                                    fill="#7489AA"
                                />
                                <path
                                    d="M40.0007 39.9974C49.2054 39.9974 56.6673 32.5355 56.6673 23.3307C56.6673 14.126 49.2054 6.66406 40.0007 6.66406C30.7959 6.66406 23.334 14.126 23.334 23.3307C23.334 32.5355 30.7959 39.9974 40.0007 39.9974Z"
                                    fill="#7489AA"
                                />
                            </svg>
                        </Box>
                    </Box>
                    <Box pb="40px" textAlign={"center"} w="full">
                        <Text color="grays.8" fontSize={"14px"} fontWeight="bold">
                            Are you sure you want to delete this price?
                        </Text>
                        <Button
                            w="full"
                            isLoading={deletingSmsPrice}
                            onClick={() => {
                                deletePrice(dataToUpdate?.id);
                            }}
                            colorScheme={"red"}
                            mt="40px"
                        >
                            Delete price
                        </Button>
                    </Box>
                </CustomModal>
            </>
        );
    };

export default SmsTable;
