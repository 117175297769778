import React from 'react';
import ReactDOM from 'react-dom/client';
import { Box, ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { getCookie } from "cookies-next";
import App from './App';
import LoggedinLayout from "./components/general/LoggedinLayout";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Login from './pages/Login';
import customTheme from "./chakra/theme";
import { logoutHandler } from './utils/functions';
import Overview from './pages/Overview/Overview';
import NotFound from './pages/NotFound';
import ChangePassword from './pages/ChangePassword';
import SMS from './pages/SMS/SMS';
import SmsPricing from './pages/SMS/SmsPricing';
import Whatsapp from './pages/Whatsapp/Whatsapp';
import WhatsappPricing from './pages/Whatsapp/WhatsappPricing';
import Business from './pages/Business';
import WhatsappNumber from './pages/Business/WhatsappNumber';
import WhatsappTemplates from './pages/Business/WhatsappTemplates';
import { RecoilRoot } from 'recoil';


const container = document.getElementById('root') as HTMLElement
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);
const loggedIn = !!getCookie("BODSQUARE_ADMIN_TOKEN");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        return failureCount === 1;
      },
      onError(err: any) {
        const errors = { ...err };
        if (
          errors.response?.data?.message?.includes("token") === "" ||
          errors?.response?.data?.error?.name.includes("token") || loggedIn === false || errors?.response?.status === 401
        ) {
          logoutHandler();
        }
      },
    },
  },
});
root.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <React.StrictMode>
        <ChakraProvider theme={customTheme}>
          <ColorModeScript />
          <BrowserRouter>
            <Box minH="100vh" w="full">
              {loggedIn ? (
                <LoggedinLayout>
                  <Routes>
                    <Route path="/" element={<App />} />
                    <Route path="/overview" element={<Overview />} />
                    <Route path="/sms" element={<SMS />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/sms/pricing" element={<SmsPricing />} />
                    <Route path="/whatsapp" element={<Whatsapp />} />
                    <Route path="/whatsapp/pricing" element={<WhatsappPricing />} />
                    <Route path="/business" element={<Business />} />
                    <Route path="/business/whatsapp-number/:numberId" element={<WhatsappNumber />} />
                    <Route path="/business/whatsapp-templates/:templateId" element={<WhatsappTemplates />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </LoggedinLayout>
              ) : (
                <Routes>
                  <Route path="/" element={<App />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              )}
            </Box>
          </BrowserRouter>
        </ChakraProvider>
      </React.StrictMode>
    </RecoilRoot>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
