import { AUTH_ROUTES } from "./routes";
import { removeCookies } from "cookies-next";

const logoutHandler = () => {
  removeCookies("BODSQUARE_ADMIN_TOKEN");
  window.location.href = AUTH_ROUTES?.LOGIN;
};

const capitalize = (str: string) => {
  const arr = str?.split(" ");
  for (var i = 0; i < arr?.length; i++) {
    arr[i] = arr[i]?.charAt(0)?.toUpperCase() + arr[i]?.slice(1);
  }
  return arr?.join(" ");
};

const removeTags = (str: string) => {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
};

export { logoutHandler, capitalize, removeTags };
