const AUTH_ROUTES = {
  LOGIN: "/login",
};

const DASH_ROUTES = {
  OVERVIEW: "/overview",
  CHANGE_PASSWORD: "/change-password",
  SMS: "/overview/sms",
  WHATSAPP: "/overview/whatsapp",
  BUSINESS: "/overview/business",
};

export { AUTH_ROUTES, DASH_ROUTES };
