export const styles = {
  global: {
    html: {
      scrollBehavior: "smooth",
    },
    body: {
      bg: "#F2F4F7",
    },
    "h1, h2, h3, h4, h5": {
      fontFamily: "Satoshi, sans-serif",
    },
    a: {
      textDecoration: "none !important",
      _hover: {
        opacity: ".8",
        // textDecoration: 'underline',
      },
    },
    ".react-datetime-picker__wrapper": {
      border: "0px",
    },
    ".search-emoji": {
      display: "none",
    },
    ".react-tel-input": {
      _focusWithin: {
        rounded: "8px",
        borderColor: "#3182ce",
        boxShadow: "0 0 0 1px #3182ce",
      },
    },
  },
};
