import React from "react";

const BusinessIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.8337 11.1328H1.66699C1.32533 11.1328 1.04199 10.8495 1.04199 10.5078C1.04199 10.1661 1.32533 9.88281 1.66699 9.88281H15.8337C16.1753 9.88281 16.4587 10.1661 16.4587 10.5078C16.4587 10.8495 16.1753 11.1328 15.8337 11.1328Z"
                fill="currentColor"
            />
            <path
                d="M12.6836 18.1257H4.81702C1.95868 18.1257 1.04199 17.2173 1.04199 14.3923V8.56732C1.04199 6.32565 1.55034 5.00065 4.13368 4.85065C4.35034 4.84232 4.57535 4.83398 4.81702 4.83398H12.6836C15.542 4.83398 16.4587 5.74232 16.4587 8.56732V14.5257C16.4253 17.2507 15.5086 18.1257 12.6836 18.1257ZM4.81702 6.08398C4.59202 6.08398 4.38368 6.09232 4.19202 6.10065C2.70035 6.19232 2.29199 6.50898 2.29199 8.56732V14.3923C2.29199 16.5257 2.64202 16.8757 4.81702 16.8757H12.6836C14.8336 16.8757 15.1837 16.5423 15.2087 14.5173V8.56732C15.2087 6.43398 14.8586 6.08398 12.6836 6.08398H4.81702Z"
                fill="currentColor"
            />
            <path
                d="M15.8337 15.15C15.6753 15.15 15.517 15.0917 15.4087 14.9833C15.2837 14.8667 15.2087 14.7 15.2087 14.525V8.56667C15.2087 6.43333 14.8586 6.08333 12.6836 6.08333H4.81702C4.59202 6.08333 4.38368 6.09167 4.19202 6.1C4.02535 6.10833 3.85864 6.04167 3.73364 5.925C3.60864 5.80833 3.54199 5.64167 3.54199 5.46667C3.57533 2.75 4.49202 1.875 7.31702 1.875H15.1836C18.042 1.875 18.9587 2.78333 18.9587 5.60833V11.4333C18.9587 13.675 18.4503 15 15.867 15.15C15.8586 15.15 15.842 15.15 15.8337 15.15ZM4.81702 4.83333H12.6836C15.542 4.83333 16.4587 5.74167 16.4587 8.56667V13.8333C17.4253 13.6583 17.7087 13.1583 17.7087 11.4333V5.60833C17.7087 3.475 17.3586 3.125 15.1836 3.125H7.31702C5.41702 3.125 4.92535 3.38333 4.81702 4.83333Z"
                fill="currentColor"
            />
            <path
                d="M5.80055 15.4668H4.36719C4.02552 15.4668 3.74219 15.1835 3.74219 14.8418C3.74219 14.5001 4.02552 14.2168 4.36719 14.2168H5.80055C6.14221 14.2168 6.42555 14.5001 6.42555 14.8418C6.42555 15.1835 6.15055 15.4668 5.80055 15.4668Z"
                fill="currentColor"
            />
            <path
                d="M10.4585 15.4668H7.5918C7.25013 15.4668 6.9668 15.1835 6.9668 14.8418C6.9668 14.5001 7.25013 14.2168 7.5918 14.2168H10.4585C10.8001 14.2168 11.0835 14.5001 11.0835 14.8418C11.0835 15.1835 10.8085 15.4668 10.4585 15.4668Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default BusinessIcon;
