import React from "react";

const SmsIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 18.1075C9.4825 18.1075 8.995 17.845 8.65 17.3875L7.525 15.8875C7.5025 15.8575 7.4125 15.82 7.375 15.8125H7C3.8725 15.8125 1.9375 14.965 1.9375 10.75V7C1.9375 3.685 3.685 1.9375 7 1.9375H13C16.315 1.9375 18.0625 3.685 18.0625 7V10.75C18.0625 14.065 16.315 15.8125 13 15.8125H12.625C12.565 15.8125 12.5125 15.8425 12.475 15.8875L11.35 17.3875C11.005 17.845 10.5175 18.1075 10 18.1075ZM7 3.0625C4.315 3.0625 3.0625 4.315 3.0625 7V10.75C3.0625 14.14 4.225 14.6875 7 14.6875H7.375C7.7575 14.6875 8.1925 14.905 8.425 15.2125L9.55 16.7125C9.8125 17.0575 10.1875 17.0575 10.45 16.7125L11.575 15.2125C11.8225 14.8825 12.2125 14.6875 12.625 14.6875H13C15.685 14.6875 16.9375 13.435 16.9375 10.75V7C16.9375 4.315 15.685 3.0625 13 3.0625H7Z"
                fill="currentColor"
            />
            <path
                d="M13.75 7.5625H6.25C5.9425 7.5625 5.6875 7.3075 5.6875 7C5.6875 6.6925 5.9425 6.4375 6.25 6.4375H13.75C14.0575 6.4375 14.3125 6.6925 14.3125 7C14.3125 7.3075 14.0575 7.5625 13.75 7.5625Z"
                fill="currentColor"
            />
            <path
                d="M10.75 11.3125H6.25C5.9425 11.3125 5.6875 11.0575 5.6875 10.75C5.6875 10.4425 5.9425 10.1875 6.25 10.1875H10.75C11.0575 10.1875 11.3125 10.4425 11.3125 10.75C11.3125 11.0575 11.0575 11.3125 10.75 11.3125Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default SmsIcon;
