import API from ".";
import { whatsappDataProps } from "../components/whatsapp/WhatsappTable";

export const getWhatsappData = async (page: number, limit: number) => {
  const response = await API.get(
    `/admin/whatsapp-pricing/?page=${page}&size=${limit}`
  );
  return response.data.data;
};

export const addWhatsappPrice = async (data: {
  country_uid: string;
  amount: number;
}) => {
  const response = await API.post(`/admin/whatsapp-pricing`, {
    country_uid: data.country_uid,
    amount: data.amount,
  });
  return response.data;
};

export const updateWhatsappPrice = async (data: whatsappDataProps) => {
  const response = await API.patch(`/admin/whatsapp-pricing/${data?.id}`, {
    country_uid: data?.country_uid,
    amount: data?.amount,
  });
  return response.data;
};

export const deleteWhatsappPrice = async (id: number) => {
  const response = await API.delete(`/admin/whatsapp-pricing/${id}`);
  return response.data;
};
