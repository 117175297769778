import {
  Pagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
  PaginationSeparator,
} from '@ajna/pagination';
import { HStack, Text, chakra } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import { FC } from 'react';
import ChakraSelect from './form/ChakraSelect';

interface PaginationProps {
  pagesCount: number;
  currentPage: number;
  handlePageChange: (nextPage: number) => void;
  pages: number[];
  setDataToShow?: Dispatch<SetStateAction<number>>;
  showEntriesSelect?: boolean;
  numberOfdataToShow?: string | number;
}

const CustomPagination: FC<PaginationProps> = (props) => {
  return (
    <HStack spacing={'20px'} mr='20px' mt='20px' justify={'end'}>
      {props?.showEntriesSelect && (
        <HStack spacing={'10px'}>
          <Text color='grays.12' fontWeight={500} fontSize='xs'>
            Show
          </Text>

          <ChakraSelect
            selectProps={{
              h: '24px',
              px: 0,
              borderWidth: '1px',
              rounded: '4px',
              borderColor: 'grays.12',
              fontWeight: 500,
              color: 'grays.12',
              fontSize: 'xs',
              value: Number(props?.numberOfdataToShow),
              onChange: (e) => {
                props?.setDataToShow &&
                  props?.setDataToShow(Number(e.target.value));
              },
            }}
          >
            <chakra.option value={10}>10</chakra.option>
            <chakra.option value={15}>15</chakra.option>
            <chakra.option value={20}>20</chakra.option>
            <chakra.option value={25}>25</chakra.option>
            <chakra.option value={30}>30</chakra.option>
          </ChakraSelect>
        </HStack>
      )}
      <Pagination
        pagesCount={props?.pagesCount}
        currentPage={props?.currentPage}
        onPageChange={props?.handlePageChange}
      >
        <PaginationContainer
          justifyContent={'space-between'}
          // ml='auto'
          w='fit-content'
        // mr='20px'
        // mt='20px'
        >
          <PaginationPrevious
            size='xs'
            borderColor={'#7489AA'}
            borderWidth='1px'
            bg='rgba(116, 137, 170, 0.04)'
            color='grays.12'
          >
            Previous
          </PaginationPrevious>
          <PaginationPageGroup
            mx={3}
            display={{ base: 'none', md: 'flex' }}
            separator={
              <PaginationSeparator
                size='xs'
                rounded='4px'
                _hover={{
                  bg: 'primary.50',
                }}
                bg='rgba(116, 137, 170, 0.04)'
                py={'4px'}
                px={'10px'}
                jumpSize={5}
              />
            }
          >
            {props?.pages.map((page: number) => (
              <PaginationPage
                size='xs'
                borderWidth='1px'
                rounded='4px'
                _current={{
                  bg: 'primary.50',
                  color: 'primary.500',
                  borderColor: 'primary.500',
                }}
                _hover={{
                  bg: 'primary.50',
                }}
                py={'4px'}
                px={'10px'}
                key={`pagination_page_${page}`}
                page={page}
              />
            ))}
          </PaginationPageGroup>
          <PaginationNext
            size='xs'
            borderColor={'#7489AA'}
            borderWidth='1px'
            bg='rgba(116, 137, 170, 0.04)'
            color='grays.12'
          >
            Next
          </PaginationNext>
        </PaginationContainer>
      </Pagination>
    </HStack>
  );
};

export default CustomPagination;
