import axios from "axios";
import { getCookie } from "cookies-next";

const tokenAuth = {
  Authorization: `Bearer ${getCookie("BODSQUARE_ADMIN_TOKEN")}`,
};
const emptyHeader = {};

const headerData = getCookie("BODSQUARE_ADMIN_TOKEN") ? tokenAuth : emptyHeader;

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: headerData,
});
