import { atom } from "recoil";

interface globalUid {
  uid: string;
}

export const globalUidAtom = atom<globalUid>({
  key: "globalUidAtom",
  default: {
    uid: "",
  },
});

export const activeState = atom<any>({
  key: "activeGlobalState",
  default: {
    active: "",
  },
});
