import React, { useState } from 'react'
import { Box, Flex, Text, Button, useDisclosure, chakra, useToast } from "@chakra-ui/react";
import WhatsappTable from "../../components/whatsapp/WhatsappTable";
import Back from '../../components/UI/Back';
import CustomModal from "../../components/UI/CustomModal";
import { usePagination } from "@ajna/pagination";
import FormInput from "../../components/UI/form/FormInput";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { addWhatsappPrice, getWhatsappData } from '../../API/whatsapp';
import Loader from '../../components/UI/Loader';
import { getCountries } from '../../API/dashboard';
import ChakraSelect from '../../components/UI/form/ChakraSelect';
import CustomPagination from '../../components/UI/CustomPagination';
import { toaster } from '../../utils/toast';

const WhatsappPricing = () => {
    const disclosure = useDisclosure();
    const [myCurrentPage, setMyCurrentPage] = useState(1);
    const [globalFilter, setGlobalFilter] = useState("");
    const [dataToShow, setDataToShow] = useState(10);
    const [country, setCountry] = useState<any>("");
    const [amount, setAmount] = useState<any>(0);

    const { data: countryData } = useQuery(["countryData"], getCountries);
    const countriesData = countryData?.countries;

    const { isLoading, data } = useQuery(
        ["whatsappData", myCurrentPage, dataToShow],
        () => {
            return getWhatsappData(myCurrentPage, dataToShow);
        }
    );
    const queryClient = useQueryClient();
    const toast = useToast();
    const { isLoading: addingPrice, mutate } = useMutation(addWhatsappPrice, {

        onSuccess(data) {
            const success = { ...data }
            toaster(toast, success)
            queryClient.invalidateQueries(["whatsappData"]);
            disclosure.onClose();
            setCountry("");
            setAmount("");
        },

        onError(data: any) {
            const errors = { ...data };
            toaster(toast, errors)
        },
        retry: false
    })

    const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
        pagesCount: data?.totalPages,
        limits: {
            outer: 3,
            inner: 3,
        },
        initialState: { currentPage: 1 },
    });

    const handlePageChange = (nextPage: number): void => {
        setCurrentPage(nextPage);
        setMyCurrentPage(nextPage);
    };


    return (
        <>
            <Box mt={"47px"} pl={"32px"} w="full" maxW={"80vw"} h="full">
                <Back
                    path='/whatsapp'
                    title='Back'
                    textProps={{
                        fontSize: '12px',
                        color: 'grays.12',
                    }}
                />
                <Flex alignItems={"center"} mb={6}>
                    <Text fontWeight={700} fontSize={"20px"}>Whatsapp Pricing</Text>
                    <Button
                        onClick={disclosure.onOpen}
                        colorScheme={"primary"}
                        ml="auto"
                        size={"sm"}
                        fontSize="14px"
                        w={"202px"}
                        px={"60px"}
                    // isDisabled={isLoading}
                    >
                        Add New Whatsapp Price
                    </Button>
                </Flex>

                {!isLoading && data?.docs?.length === 0 && (
                    <Text>No data yet</Text>
                )}
                {isLoading && <Loader />}


                {!isLoading && (
                    <Box bg={"#fff"}>
                        <Box h='60vh' overflowY={'auto'}>
                            <WhatsappTable
                                whatsappData={data?.docs}
                                globalFilter={globalFilter}
                                setGlobalFilter={setGlobalFilter}
                                dataToShow={dataToShow}
                                setDataToShow={setDataToShow}
                            />
                        </Box>
                        <Box py={4}>
                            <CustomPagination
                                pagesCount={pagesCount}
                                currentPage={currentPage}
                                handlePageChange={handlePageChange}
                                pages={pages}
                                numberOfdataToShow={dataToShow}
                                setDataToShow={setDataToShow}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
            {/* Edit Whatsapp Pricing */}
            <CustomModal
                disclosure={disclosure}
                title={
                    <Text fontSize={"20px"} color="grays.8" mt="35px" px="38px">
                        Add New Whatsapp Price
                    </Text>
                }
            >
                <Box mt="42px" pb="40px" textAlign={"center"} w="full">
                    <Box>
                        <ChakraSelect
                            label="Country"
                            selectProps={{
                                h: "44px",
                                marginTop: "5px",
                                borderColor: "borders.100",
                                color: "grays.9",
                                value: country,
                                placeholder: "Select a country",
                                onChange: (e) => {
                                    setCountry(e.target.value);
                                },
                            }}
                        >
                            {countriesData?.map((country: any) => (
                                <chakra.option key={country?.uid} value={country?.uid}>{country.name}</chakra.option>
                            ))}
                        </ChakraSelect>
                        <FormInput
                            label="Amount(USD)"
                            type="number"
                            for="amount"
                            inputProps={{
                                placeholder: "Enter amount",
                                marginBottom: "20px",
                                value: amount,
                                onChange: (e) => {
                                    setAmount(e.target.value);
                                },
                            }}
                        />
                    </Box>
                    <Button
                        w="full"
                        isLoading={addingPrice}
                        isDisabled={!country || !amount}
                        colorScheme={"primary"}
                        onClick={() => {
                            mutate({
                                country_uid: country,
                                amount: amount
                            });
                        }}
                        mt="40px"
                    >
                        Add Pricing
                    </Button>
                </Box>
            </CustomModal>
        </>
    )
}

export default WhatsappPricing