import { Box, VStack, HStack, Text } from '@chakra-ui/react'
import React from 'react'
import { NavLink } from 'react-router-dom';
import BusinessIcon from '../../icons/BusinessIcon';
import { SidebarLogo } from "../../icons/Logo";
import OverviewIcon from '../../icons/OverviewIcon';
import PasswordIcon from '../../icons/PasswordIcon';
import SmsIcon from '../../icons/SmsIcon';
import WhatsappIcon from '../../icons/WhatsappIcon';
import { logoutHandler } from '../../utils/functions';

const Sidebar = () => {

    const activeStyle = {
        color: '#FFFFFF',
        backgroundColor: '#202938',
        width: '100%',
        borderRadius: '4px',
    };

    const normalStyle = {
        color: '#707B87',
        width: '100%',
        borderRadius: '4px',
    };
    const logoutStyle = {
        color: '#707B87',
        width: '100%',
        borderRadius: '4px',
        cursor: 'pointer'
    };

    const channelNavLinks = [
        {
            title: 'SMS',
            path: '/sms',
            icon: <SmsIcon />,
        },
        {
            title: 'Whatsapp',
            path: '/whatsapp',
            icon: <WhatsappIcon />,
        },
    ]

    return (
        <VStack justifyContent={"space-between"} px={4} pt={"39.09px"} backgroundColor={"backgrounds.500"} w={'224px'} h={"100vh"}>
            <Box>
                <Box mb={"57.7px"}>
                    <SidebarLogo />
                </Box>
                <NavLink
                    to={'/overview'}
                    style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
                >
                    <HStack
                        spacing={'4px'}
                        py='12px'
                        px='16px'
                        w='full'
                        _hover={{
                            bg: 'rgba(0, 102, 255, 0.03)',
                        }}
                        rounded='4px'
                    >
                        <OverviewIcon />
                        <Text fontWeight={500}>Overview</Text>
                    </HStack>
                </NavLink>
                <Text color={"#FFFFFF"} w="full" mt={"26px"} mb={"16px"}>Channels</Text>
                {channelNavLinks?.map((navData) => {
                    return (
                        <NavLink
                            key={navData?.path}
                            to={navData?.path}
                            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
                        >
                            <HStack
                                spacing={'4px'}
                                py='12px'
                                px='16px'
                                w='full'
                                _hover={{
                                    bg: 'rgba(0, 102, 255, 0.03)',
                                }}
                                rounded='4px'
                            >
                                {navData?.icon}
                                <Text fontWeight={500}>{navData?.title}</Text>
                            </HStack>
                        </NavLink>
                    );
                })}
                <Text color={"#FFFFFF"} w="full">Others</Text>
                <NavLink to={`/business`}
                    style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
                >
                    <HStack
                        spacing={'4px'}
                        py='12px'
                        px='16px'
                        w='full'
                        _hover={{
                            bg: 'rgba(0, 102, 255, 0.03)',
                        }}
                        rounded='4px'
                    >
                        <BusinessIcon />
                        <Text fontWeight={500}>Businesses</Text>
                    </HStack>
                </NavLink>
                <Text color={"#FFFFFF"} w="full">Settings</Text>
                <NavLink to={`/change-password`}
                    style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
                >
                    <HStack
                        spacing={'4px'}
                        py='12px'
                        px='16px'
                        w='full'
                        _hover={{
                            bg: 'rgba(0, 102, 255, 0.03)',
                        }}
                        rounded='4px'
                    >
                        <PasswordIcon />
                        <Text fontWeight={500}>Change password</Text>
                    </HStack>
                </NavLink>
            </Box>
            <Box my={"30px"}>
                <Box onClick={logoutHandler}
                    style={logoutStyle}
                >
                    <HStack
                        spacing={'4px'}
                        py='12px'
                        px='16px'
                        w='full'
                        _hover={{
                            bg: 'rgba(0, 102, 255, 0.03)',
                        }}
                        rounded='4px'
                    >
                        <PasswordIcon />
                        <Text fontWeight={500}>Log out</Text>
                    </HStack>
                </Box>
            </Box>
        </VStack>
    )
}

export default Sidebar