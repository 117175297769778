import {
  Box,
  FormControl,
  FormControlProps,
  FormLabel,
  Select,
  SelectProps,
  Text,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface FormInputProps {
  label?: string;
  labelIcon?: any;
  helperText?: string;
  formControlProps?: FormControlProps;
  selectProps?: SelectProps;
  children?: ReactNode;
}

const ChakraSelect: FC<FormInputProps> = (props) => {
  return (
    <FormControl {...props.formControlProps}>
      {props.label && (
        <FormLabel
          fontWeight={'bold'}
          fontSize='sm'
          color='grays.9'
          mb='4px'
          display='flex'
        >
          <Text as='span'>{props?.label}</Text>

          {props?.labelIcon && (
            <Box ml='auto' my='auto'>
              {props.labelIcon}
            </Box>
          )}
        </FormLabel>
      )}

      <Select
        borderRadius={'4px'}
        borderColor='borders.100'
        _hover={{
          borderColor: 'borders.100',
        }}
        color='grays.9'
        _placeholder={{
          color: 'grays.6',
        }}
        h={props?.selectProps?.height || props?.selectProps?.h || '44px'}
        bg='white'
        fontSize='sm'
        {...props?.selectProps}
      >
        {props?.children}
      </Select>
    </FormControl>
  );
};

export default ChakraSelect;
