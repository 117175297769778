import API from ".";

export const getDashboardData = async () => {
  const response = await API.get(`/admin/dashboard`);
  return response.data.data;
};

export const getCountries = async () => {
  const response = await API.get("/auth/extra-info/");
  return response.data.data;
};
