import React, { FC } from 'react';

const ChevronDown: FC<any> = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.00099 11.1994C7.53432 11.1994 7.06766 11.0194 6.71432 10.6661L2.36766 6.31944C2.17432 6.12611 2.17432 5.80611 2.36766 5.61277C2.56099 5.41944 2.88099 5.41944 3.07432 5.61277L7.42099 9.95944C7.74099 10.2794 8.26099 10.2794 8.58099 9.95944L12.9277 5.61277C13.121 5.41944 13.441 5.41944 13.6343 5.61277C13.8277 5.80611 13.8277 6.12611 13.6343 6.31944L9.28766 10.6661C8.93432 11.0194 8.46766 11.1994 8.00099 11.1994Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ChevronDown;
