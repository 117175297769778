import React from "react";

const PasswordIcon = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.5 8.0625C13.1925 8.0625 12.9375 7.8075 12.9375 7.5V6C12.9375 3.6375 12.27 2.0625 9 2.0625C5.73 2.0625 5.0625 3.6375 5.0625 6V7.5C5.0625 7.8075 4.8075 8.0625 4.5 8.0625C4.1925 8.0625 3.9375 7.8075 3.9375 7.5V6C3.9375 3.825 4.4625 0.9375 9 0.9375C13.5375 0.9375 14.0625 3.825 14.0625 6V7.5C14.0625 7.8075 13.8075 8.0625 13.5 8.0625Z"
                fill="currentColor"
            />
            <path
                d="M12.75 17.0625H5.25C1.9425 17.0625 0.9375 16.0575 0.9375 12.75V11.25C0.9375 7.9425 1.9425 6.9375 5.25 6.9375H12.75C16.0575 6.9375 17.0625 7.9425 17.0625 11.25V12.75C17.0625 16.0575 16.0575 17.0625 12.75 17.0625ZM5.25 8.0625C2.565 8.0625 2.0625 8.5725 2.0625 11.25V12.75C2.0625 15.4275 2.565 15.9375 5.25 15.9375H12.75C15.435 15.9375 15.9375 15.4275 15.9375 12.75V11.25C15.9375 8.5725 15.435 8.0625 12.75 8.0625H5.25Z"
                fill="currentColor"
            />
            <path
                d="M6 12.7492C5.9025 12.7492 5.805 12.7267 5.715 12.6892C5.6175 12.6517 5.54251 12.5992 5.46751 12.5317C5.33251 12.3892 5.25 12.2017 5.25 11.9992C5.25 11.9017 5.27249 11.8042 5.30999 11.7142C5.34749 11.6167 5.40001 11.5417 5.46751 11.4667C5.54251 11.3992 5.6175 11.3467 5.715 11.3092C5.985 11.1892 6.32249 11.2567 6.53249 11.4667C6.59999 11.5417 6.65251 11.6242 6.69001 11.7142C6.72751 11.8042 6.75 11.9017 6.75 11.9992C6.75 12.1942 6.66749 12.3892 6.53249 12.5317C6.38999 12.6667 6.195 12.7492 6 12.7492Z"
                fill="currentColor"
            />
            <path
                d="M9 12.7507C8.805 12.7507 8.61001 12.6682 8.46751 12.5332C8.33251 12.3907 8.25 12.2032 8.25 12.0007C8.25 11.9032 8.26499 11.8057 8.30999 11.7157C8.34749 11.6257 8.40001 11.5432 8.46751 11.4682C8.64001 11.2957 8.9025 11.2132 9.1425 11.2657C9.195 11.2732 9.24 11.2882 9.285 11.3107C9.33 11.3257 9.375 11.3482 9.42 11.3782C9.4575 11.4007 9.49499 11.4382 9.53249 11.4682C9.59999 11.5432 9.65251 11.6257 9.69001 11.7157C9.72751 11.8057 9.75 11.9032 9.75 12.0007C9.75 12.2032 9.66749 12.3907 9.53249 12.5332C9.49499 12.5632 9.4575 12.5932 9.42 12.6232C9.375 12.6532 9.33 12.6757 9.285 12.6907C9.24 12.7132 9.195 12.7282 9.1425 12.7357C9.0975 12.7432 9.045 12.7507 9 12.7507Z"
                fill="#707B87"
            />
            <path
                d="M12 12.7507C11.7975 12.7507 11.61 12.6682 11.4675 12.5332C11.4 12.4582 11.3475 12.3757 11.31 12.2857C11.2725 12.1957 11.25 12.0982 11.25 12.0007C11.25 11.8057 11.3325 11.6107 11.4675 11.4682C11.505 11.4382 11.5425 11.4082 11.58 11.3782C11.625 11.3482 11.67 11.3257 11.715 11.3107C11.76 11.2882 11.805 11.2732 11.85 11.2657C12.0975 11.2132 12.3525 11.2957 12.5325 11.4682C12.6675 11.6107 12.75 11.7982 12.75 12.0007C12.75 12.0982 12.7275 12.1957 12.69 12.2857C12.6525 12.3832 12.6 12.4582 12.5325 12.5332C12.39 12.6682 12.195 12.7507 12 12.7507Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default PasswordIcon;
