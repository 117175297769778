import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  InputProps,
  FormControlProps,
  InputGroup,
  InputRightElement,
  Button,
  Text,
  Box,
  TextareaProps,
  Textarea,
  InputLeftElement,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';

interface FormInputProps {
  label?: string;
  labelIcon?: any;
  for: string;
  type: string;
  helperText?: string;
  inputProps?: InputProps;
  textareaProps?: TextareaProps;
  formControlProps?: FormControlProps;
  rightElement?: any;
  leftElement?: any;
  listLabel?: string;
  listData?: string[];
  ref?: any;
  disabled?: boolean;
}

const FormInput: FC<FormInputProps> = (props) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <FormControl {...props.formControlProps}>
      {props.label && (
        <FormLabel
          fontWeight={'bold'}
          fontSize='sm'
          color='grays.9'
          htmlFor={props.for}
          mb='4px'
          display='flex'
        >
          <Text as='span'>{props.label}</Text>

          {props.labelIcon && (
            <Box ml='auto' my='auto'>
              {props.labelIcon}
            </Box>
          )}
        </FormLabel>
      )}

      <InputGroup size='md'>
        {props.leftElement && (
          <InputLeftElement pointerEvents='none'>
            {props.leftElement}
          </InputLeftElement>
        )}

        {props.type !== 'textarea' ? (
          <Input
            disabled={props.disabled}
            borderRadius={'8px'}
            borderColor='borders.100'
            _hover={{
              borderColor: 'borders.100',
            }}
            color='grays.9'
            _placeholder={{
              color: 'grays.6',
            }}
            h='44px'
            bg='white'
            id={props.for}
            type={!show ? props.type : 'text'}
            list={props.listLabel}
            {...props.inputProps}
            ref={props?.ref}
            fontSize='sm'
          />
        ) : (
          <Textarea
            pt={4}
            bg='white'
            borderRadius={'8px'}
            borderColor='borders.100'
            _hover={{
              borderColor: 'borders.100',
            }}
            color='grays.9'
            h='96px'
            _placeholder={{
              color: 'grays.6',
            }}
            {...props.textareaProps}
            ref={props?.ref}
            fontSize='sm'
          />
        )}

        <datalist id={props.listLabel}>
          {props.listData?.map((value) => (
            <option key={value} value={value} />
          ))}
        </datalist>

        {(props.rightElement || props.type === 'password') && (
          <InputRightElement>
            {props.rightElement}
            {props.type === 'password' && (
              <Button
                size='sm'
                mt={2}
                mr={2}
                p={0}
                fontSize='lg'
                variant={'ghost'}
                onClick={handleClick}
                color='gray.400'
              >
                {!show ? <HiOutlineEyeOff /> : <HiOutlineEye />}
              </Button>
            )}
          </InputRightElement>
        )}
      </InputGroup>

      {props.helperText && (
        <FormHelperText fontSize={'xs'} fontWeight={700} color='grays.8'>
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormInput;
